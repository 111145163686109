import "./navbar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/js/src/collapse.js";
import "bootstrap/dist/css/bootstrap.css";
import "../../../node_modules/jquery/dist/jquery.min.js";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import {useState} from "react";

import NavDropdown from "react-bootstrap/NavDropdown";
import baseurl from "../../settings/urlsettings";
import {navigate} from "use-history";
import PopUpAuth from "../authentication/PopUpComponents/PopUpAuth";
import {Button} from "react-bootstrap";


function Navbar_ex(props) {
    const [user, setUser] = useState(props.user);
    const submit = async (e) => {
        e.preventDefault();
        console.log("logeout");
        await fetch(baseurl + "/logout", {

            method: "Get",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        window.location.reload();
        navigate("/");
    };
    const renderLimitedText = (text) => {
        if (text.length > 20) {
            return text.slice(0, 20) + "...";
        } else {
            const spacesToAdd = Math.floor((20 - text.length) / 2);
            return "\u00A0".repeat(spacesToAdd) + text + "\u00A0".repeat(spacesToAdd);

        }
    };
    return (
        <div className="navbar-component">

            <Navbar className="fixed-top nav-back" variant="dark" expand="lg">
                <div className="container-fluid">

                    <Navbar.Brand href="/homepage"> <img className="logo"
                                                         src={require("../../assets/images/logo (2).png")}
                                                         alt={"site logo"}/>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">

                        </Nav>
                        <Nav>

                            <Nav.Link className=" nav-items nav-st" href="/blog">Blog</Nav.Link>

                            {
                                !user &&
                                <PopUpAuth user={user} setUser={props.setUser} facebook={props.facebook}
                                           google={props.google}/>
                            }
                            {
                                user &&

                                <NavDropdown title={renderLimitedText(user)}
                                             id="userdropbox" data-toggle="dropdown"

                                             placement="auto-start"
                                >

                                    < NavDropdown.Item href="/developerDashboard"
                                                       id="nevItem">
                                        Dashboard
                                    </NavDropdown.Item>

                                    {/*< NavDropdown.Item href="/Dashboard"*/}
                                    {/*                   id="nevItem">*/}
                                    {/*    Dashboard</NavDropdown.Item>*/}
                                    < NavDropdown.Item href="/homepage"
                                                       id="nevItem"
                                                       onClick={submit}>Logout</NavDropdown.Item>

                                </NavDropdown>

                            }
                            <Nav.Item className="nav-items nav-st">
                                <Button className={"book_demo"}
                                        onClick={e => props.setShowdemo(true)}
                                >
                                    Sign up for Beta
                                </Button>

                            </Nav.Item>

                        </Nav>

                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>

    );
}

export default Navbar_ex;