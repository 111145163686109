import "./normalize.css"
import "./homepage.css"
import 'bootstrap/dist/css/bootstrap.css';
import "../carousle/card.css";
import "react-multi-carousel/lib/styles.css";
import Card from "../carousle/card";
import secondvideo from "../../assets/images/lumion10.mp4"
import mv2 from "../../assets/images/MorseVerse-2.mp4"
import {productData, responsive} from "./data/productdata.js";
import BackgroundSlider from './slider.js'
import React, {useState} from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from "react-bootstrap";
import {homePageInfo, logImages} from "./data/page_info";
import Bookdemo from "../bookDemo/bookdemo";
import Carousel from "react-multi-carousel";

const HomePageRow = ({index, title, text, image, reverse, isVideo, videoSrc}) => (
    <Row className={`homepage_info_row ${reverse ? 'reverse' : ''}`}>
        <Col>
            {reverse ? (
                isVideo ? (
                    <video className={index === 1 ? "homepage_info_video_index1" : "homepage_info_video"} controls>
                        <source src={videoSrc} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={image} className={index === 1 ? "homepage_info_image_index1" : "homepage_info_image"}/>
                )
            ) : (
                <div>
                    <h1>{title}</h1>
                    <p className="homepage_info_text">{text}</p>
                </div>
            )}
        </Col>

        <Col>
            {!reverse ? (
                isVideo ? (
                    <video playsInline
                           autoPlay
                           muted
                           loop
                           className={index === 1 ? "homepage_info_image_index1" : "homepage_info_image"} controls>
                        <source src={videoSrc} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={image} className={index === 1 ? "homepage_info_image_index1" : "homepage_info_image"}/>
                )
            ) : (
                <div>
                    <h1>{title}</h1>
                    <p className="homepage_info_text">{text}</p>
                </div>
            )}
        </Col>
    </Row>
);

export default function Homepage(props) {

    const title = "AI managed virtual shops";
    const card = productData.map((item) => (

        <Card
            key={item.id}
            name={item.name}
            imageurl={item.imageurl}
            url={item.url}
            price={item.price}
            display={item.display}
            description={item.description}
            link={item.link}
            linktext={item.linktext}
        />
    ));

    return (
        <div className="homepage-component">
            <Container>

                <Row>
                    <h1 className={"homepage_title"}>
                        {title}
                    </h1>
                    <BackgroundSlider/>
                </Row>
                <Row className="row allsa">
                    <hr className="wis5"/>
                </Row>
                <Row>
                    <h3 className={"homepage_log_title_h3"} > Featured In :</h3>
                </Row>
                <Row className={"homepage_log_setting"}>
                    {
                        logImages.map((data, index) => (
                            <a href={"https://www.f6s.com/morseverse"}>
                                <img className={"logo_image"}
                                     src={data}
                                     alt={"logo_iamga" + index}
                                />

                            </a>

                        ))
                    }
                </Row>
                <Row className="row allsa">
                    <hr className="wis5"/>
                </Row>
                <Row className={"book_demo_banner"}>

                    <Col/>
                    <Col sm={12} md={12} lg={9}>
                        <h1>Why Virtual Shops?</h1>
                        <p className={"Book_demo_banner_text"}>
                            Using a virtual shop for your business expands your global reach, increases sales with 24/7
                            availability,
                            automates tasks, and reduces overhead costs. Our AI-driven virtual shops delivers
                            personalized shopping experiences,
                            boosting customer satisfaction and loyalty. Elevate your brand and connect with customers
                            like never before by
                            embracing the future of retail with a virtual shop.
                        </p>
                    </Col>

                    <Col/>
                </Row>

                <Row className="row allsa">
                    <hr className="wis5"/>
                </Row>
                <Row className={"homepage_info"}>

                    {homePageInfo.map((data, index) => (
                        <HomePageRow key={index} index={index} title={data.title} text={data.text} image={data.image}
                                     reverse={index % 2 !== 0} isVideo={data.videoSrc} videoSrc={data.videoSrc}/>
                    ))}
                </Row>
                {/*<Row className="row allsa">*/}
                {/*    <hr className="wis5"/>*/}
                {/*    <h1 className="topicsas">News</h1>*/}
                {/*</Row>*/}
                {/*<Row>*/}
                {/*    <Container className="container">*/}
                {/*        <Row className="row">*/}
                {/*            <div className="container">*/}
                {/*                <div className="row">*/}
                {/*                    <Carousel showDots={false} responsive={responsive}>*/}
                {/*                        {card}*/}
                {/*                    </Carousel>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Row>*/}
                {/*    </Container>*/}
                {/*</Row>*/}
                <Row className="row allsa">
                    <hr className="wis5"/>
                </Row>
                <Row className={"book_demo_banner"}>

                    <Col/>
                    <Col sm={12} md={12} lg={9}>
                        <p className={"Book_demo_banner_text"}>
                            Sign up to join the waiting list for the beta version and be among the first to use
                            MorseVerse's virtual shops.
                        </p>
                        <Button className={"Book_demo_button"}
                                onClick={e => props.setShowdemo(true)}>
                            Sign up for Beta
                        </Button>
                    </Col>

                    <Col/>

                </Row>
                <Row className="row allsa">
                    <hr className="wis5"/>
                </Row>
                <Row>
                    <div style={{"marginBottom": "1%"}}>
                        <div className="row">
                            <div className="row allsa">
                                {/*<hr className="wis5"/>*/}
                                <h1 className="topicsas">Backers and Partners</h1>
                            </div>
                        </div>

                        <Row>
                            <Col xs={12} md={3} className="mb-4 mb-md-0">
                                <a href="https://cloud.google.com/startup/web3" className={"collaborator_section"}
                                   onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                   onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                                    <Image src={require('../../assets/images/google.png')}
                                           style={{width: '100px', height: '70px', transition: '0.3s'}}/>
                                    <h5 style={{
                                        marginTop: '10px',
                                        color: '#6c757d',
                                        fontFamily: 'Arial, sans-serif'
                                    }}>Google Cloud Startup program</h5>
                                </a>
                            </Col>

                            <Col xs={12} md={3}>
                                <a href="https://github.com/enterprise/startups" className={"collaborator_section"}
                                   onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                   onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                                    <Image src={require('../../assets/images/25231.png')}
                                           style={{height: '70px', transition: '0.3s'}}/>
                                    <h5 style={{
                                        marginTop: '10px',
                                        color: '#6c757d',
                                        fontFamily: 'Arial, sans-serif'
                                    }}>Github for startups</h5>
                                </a>
                            </Col>
                            <Col xs={12} md={3}>
                                <a href="https://www.mongodb.com/solutions/startups" className={"collaborator_section"}
                                   onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                   onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                                    <Image src={require('../../assets/images/MongoDB_Logo.svg.png')}
                                           style={{height: '70px', transition: '0.3s'}}/>
                                    <h5 style={{
                                        marginTop: '10px',
                                        color: '#6c757d',
                                        fontFamily: 'Arial, sans-serif'
                                    }}>MongoDB for Startups program</h5>
                                </a>
                            </Col>
                            <Col xs={12} md={3}>
                                <a href="https://readyplayer.me" className={"collaborator_section"}
                                   onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                   onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                                    <Image src={require('../../assets/images/ReadyPlayerMe-Logo-FullBlack-01.png')}
                                           style={{width: '100px', height: '70px', transition: '0.3s'}}/>
                                    <h5 style={{
                                        marginTop: '10px',
                                        color: '#6c757d',
                                        fontFamily: 'Arial, sans-serif'
                                    }}>Ready
                                        Player Me</h5>
                                </a>
                            </Col>
                        </Row>

                    </div>

                </Row>


            </Container>


        </div>
    );
}

