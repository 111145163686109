export const logImages = [
    require('./../../../assets/homepage/logo/mail.png'),
    // require('./../../../assets/homepage/logo/01.jpg'),
    // require('./../../../assets/homepage/logo/01.jpg'),
    // require('./../../../assets/homepage/logo/01.jpg'),
];
export const homePageInfo = [
    {
        index: 1,
        title:"Personalise your virtual shop",
        text: "We personalise virtual shops by seamlessly blending AI-generated content with your\n"+
        "brand assets like logos and images. Our platform intelligently adapts to your specifications,\n"+
        "ensuring your virtual shop reflects your unique identity and resonates with your target audience. ",
        image:null,
        videoSrc: require('./../../../assets/homepage/page_info/Personalise.mp4')
    },
    {
        index: 2,
        title: "Train your AI agent",
        text: "We train AI agents using your business’s unique data from your website, documentation, or ERP system.\n" +
            " This ensures the AI accurately represents your products and services. The AI agent is continuously updated\n" +
            "in real time, providing informed responses and adapting to changes.",
        image: require('./../../../assets/homepage/page_info/fastRelayable.png'),
        videoSrc : null
    },
    {
        index: 3,
        title:"Integrate Your virtual shop",
        text: "You can use your virtual shop by sharing a unique link with customers, allowing them easy\n" +
            "access from anywhere.Additionally, you can seamlessly integrate the virtual shop into your\n" +
            "existing platforms, such as websites, apps, and social media.",
        image: require('./../../../assets/homepage/page_info/suport.jpg'),
        videoSrc : null
    },
    {
        index: 4,
        title:"Control your virtual organization",
        text: "Control your virtual shop through an intuitive dashboard. Manage your agent, update content,\n" +
            "track sales, and gain customer insights easily. With real-time updates and user-friendly\n" +
            " controls, you can keep your virtual shop current and aligned with your business goals.",
        image: require('./../../../assets/homepage/page_info/developer_dashbord.png'),
        videoSrc : null

    },
    // {
    //     index: 5,
    //     title: "How Our AI Agents Work",
    //     text: "Provide us with your documents or business information, and we will tailor an AI agent to support your specific business needs.\n" +
    //         "Our AI agents can interact through voice messages or text, ensuring versatility in communication.\n" +
    //         "Experience our AI in action in a custom 3D environment, complete with realistic characters, enhancing user engagement."
    //
    // }
]

