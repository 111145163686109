import React, {useState} from 'react';
import {
    MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBCheckbox
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './register.css'
import {Link, Navigate} from "react-router-dom";
import {subsribeLetter} from "../loginpage/popup";
import MessageBox from "../messageBox/MessageBox";
import baseurl from "../../settings/urlsettings";


export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export function isValidPassword(password) {
    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;
    if (!password.match(lowerCase)) {
        return "Password should contains lowercase letters!";
    } else if (!password.match(upperCase)) {
        return "Password should contain uppercase letters!";
    } else if (!password.match(numbers)) {
        return "Password should contains numbers also!";
    } else if (password.length < 8) {
        return "Password length should be more than 8.";
    } else {
        return null;
    }
}


function SignUp() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [policy, setPolicy] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const [error, setError] = useState(null);
    const handleEmailChange = event => {
        if (!isValidEmail(event.target.value)) {
            setError('Email is invalid');
        } else {
            setError(null);
        }
        setEmail(event.target.value);
    };
    const handlePasswordChange = event => {
        setError(isValidPassword(event.target.value))
        setPassword(event.target.value);

    };

    const submit = async event => {

        event.preventDefault();
        setError(isValidPassword(password))
        const urlParams = new URLSearchParams(window.location.search);
        const isPromo = urlParams.get('field') === 'promo';
        if (error != null)
            return;
        if (newsletter) {
            subsribeLetter({"email": email});
        }

        await fetch(baseurl + '/register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "name": username.toLowerCase(),
                email,
                password,
                "date":new Date().toString(),
                "promo": isPromo
            })
        }).then(async response => {
            if (response.ok) {
                if (isPromo) {
                    MessageBox({
                        type: "success",
                        text: "Your signup is completed. Promo successfully sent to email."
                    });
                } else {
                    MessageBox({
                        type: "success",
                        text: "Your signup is completed"
                    });
                }
                setRedirect(true);
            } else if (response.status === 400) {
                MessageBox({
                    type: "error",
                    text: "Invalid request"
                });

            } else if (response.status === 409) {
                MessageBox({
                    type: "error",
                    text: await response.text()
                });


            } else {
                MessageBox({
                    type: "error",
                    text: "An error occurred"
                });
            }
        })
            .catch(error => {
                MessageBox({
                    type: "error",
                    text: "A network error occurred"
                });

            })

    }


    const disableButton = () => {

        if (error ||
            username.trim() === "" ||
            password.trim() === "" ||
            email.trim() === "" ||
            !policy
        )
            return true;
        else
            return false;
    }
    return (<div className='body_signin'>


        <MDBContainer fluid className="py-5">

            <MDBRow className='messs d-flex justify-content-center align-items-center h-100'>
                <MDBCol col='12'>

                    <MDBCard className='bg-dark text-white my-5 mx-auto mes optsmes'>
                        <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                            <h2 className="messss fw-bold mb-2 text-uppercase">Register</h2>
                            <p className="text-white-50 mb-4">Please enter your information!</p>
                            <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                      labelClass='text-white'
                                      label='Username'
                                      id='form1' type='text' size="lg"
                                      onChange={e => setUsername(e.target.value)}
                            />
                            <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                      labelClass='text-white'
                                      label='Email address'
                                      id='formControlLg' type='email' size="lg"
                                      onChange={handleEmailChange}


                            />
                            <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                      labelClass='text-white'
                                      label='Password'
                                      id='formControlLg'
                                      type='password'
                                      size="lg"
                                      onChange={handlePasswordChange}

                            />
                            {redirect &&
                                <Navigate to="/Signin" replace={true}/>
                            }

                            {error &&

                                <p className="text-white-50 mb-4">{error} </p>
                            }

                            <MDBRow className='mb-4  mx-5 w-100 '>
                                <MDBCol className='d-flex' id="checkboxRegform">
                                    <MDBCheckbox name='flexCheck'
                                                 onChange={e => setNewsletter(e.target.checked)}
                                                 id='flexCheckDefault'/>
                                    <text>
                                        Subscribe to our newsletter
                                    </text>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-4 mx-5 w-100 '>
                                <MDBCol className='d-flex' id="checkboxRegform">
                                    <MDBCheckbox name='flexCheck'
                                                 onChange={e => setPolicy(e.target.checked)}
                                                 id='flexCheckDefault'/>
                                    <Link to="/Privacypolicy">
                                        Agree the terms and condition
                                    </Link>
                                </MDBCol>
                            </MDBRow>

                            <MDBBtn outline className='dsalis mx-2 px-5' color='white' size='lg'
                                    type="submit"
                                    onClick={submit} disabled={disableButton()}>
                                Sign Up
                            </MDBBtn>


                            <div>
                                <p className="jesss mb-0">Do you have an account?
                                    <a href="signin"
                                       class="text-white-50 fw-bold">
                                        &nbsp; Sign In
                                    </a>
                                </p>

                            </div>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
            </MDBRow>

        </MDBContainer>
    </div>);
}

export default SignUp;
