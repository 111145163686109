import "./BlogPost.css"
import React, { useState } from 'react';
import { Topicheader } from './Topicheader';




export  function BlogPost({ blog }) {
    const bodyLines = blog.body.split('\n');
    return(
        <div className="blog1">
            {Topicheader}
            <div className="parent-container">
                <div className="container-blog">
                    <div className="row container-blog">
                        <div>
                            <h4 className="blog-h4">{blog.title}</h4>
                            <p className="name-blog">{blog.author}</p>
                            <span className="date-blog"><p>{blog.data}</p></span>
                            {bodyLines.map((line, index) => (
                                <h4 className={"blog_body"} key={index}>{line}</h4>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
