import "./marketplace.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bg from "./coming_soon_marketplace.jpg"
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import validator from 'validator';
import baseurl from "../../settings/urlsettings";

function Marketplace() {
  const notify = () => toast("Subscibtion is completed");
const [email, setEmail] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const validateEmail = () => {
  if (validator.isEmail(email)) {
    setErrorMessage('');
  } else {
    setErrorMessage('Please enter a valid email');
  }
};

useEffect(() =>{
  let x = setInterval(function() {
    let now = new Date()
    let selisih = comingDate.getTime() - now.getTime()
    let days    = Math.floor(selisih / (1000 * 60 * 60 * 24))
    let hours   = Math.floor(selisih % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
    let minutes = Math.floor(selisih % (1000 * 60 * 60) / (1000 * 60))
    let seconds = Math.floor(selisih % (1000 * 60) / 1000)
  
    // d.innerHTML = getTrueNumber(days)
  
    document.getElementById('days').innerHTML = getTrueNumber(days)
  
    document.getElementById('hours').innerHTML = getTrueNumber(hours)
    document.getElementById('minutes').innerHTML = getTrueNumber(minutes)
    document.getElementById('seconds').innerHTML = getTrueNumber(seconds)
  
  
  
  
    
  }, 1000)
  return () => clearInterval(x);
}, []);
function onClickSubmit(event){
  event.preventDefault()
  const data = new FormData(event.target);
  if (data.get('email') == ""){
    toast.error('Please fill the email box', {
      position: toast.POSITION.TOP_CENTER,
      autoClose:2000
  });
  }else{
  
  var date = new Date();
  var req = {"email":data.get('email'), "date":date.toString()} 
  
  setEmail('')
  toast.success('Subscribtion is completed', {
    position: toast.POSITION.TOP_CENTER,
    autoClose:2000
});
  fetch(baseurl + "/person", {

  // fetch('https://www.morseverse.com/api/v1/person', {

    method: 'POST',
    body: JSON.stringify(req),
  })
}

}
  return (
    
    <div className="marketplace-component">
      <div className="row row-opt">
        <div className=" col-lg-6 col-md-6 col-sm-12" >

          <div className="row nonoss">
            <div className="customes">
            </div>
          </div>

          <div className="row row-optt">
            <div className="time buttom nonoss">
              <span ><div  id="days">-</div> Days</span>
              <span><div id="hours">-</div> Hours</span>
              <span><div id="minutes">-</div> Minutes</span>
              <span><div id="seconds">-</div> Seconds</span>
            </div>          
       
          </div>
        </div>

        <div className=" col-lg-6 col-md-6 col-sm-12 colmes">
          <div className="newslatter middle">
            <h2>We are Coming Soon</h2>
            <h4>Subscribe to get notified when we start</h4>
            <form onSubmit={onClickSubmit}>
              <input  id="email" type="email" name="email" placeholder="Enter Your Email"  value={email}  onChange={(e) => setEmail(e.target.value)} onBlur={validateEmail} />
              <button  type="submit" className="newslatter-button">Subscribe</button>
            </form>
          </div>
        </div>
       
      </div>
      
    </div>
  );
}


let comingDate = new Date('Jun 10, 2023 13:12:00')

let d = document.getElementById('days')
let h = document.getElementById('hours')
let m = document.getElementById('minutes')
let s = document.getElementById('seconds')


function getTrueNumber(x) {
  if (x < 10) return '0' + x
  else return x
}

export default Marketplace;

