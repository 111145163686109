import React, {useState, useEffect} from 'react';
import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes} from '@mui/x-data-grid';
import './adminpage.css';
import {Sidebarsdata} from './sidebarsdata';
import baseurl from "../../settings/urlsettings.js";
import { fetchWaitlistData, updateWaitingList} from "./api_for_adminpage";
import MessageBox from "../messageBox/MessageBox";
import {Grid} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Close";


export default function Waitinglistadmin() {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});

    useEffect(() => {
        fetchWaitlistData().then((data) => setRows(data));
    }, []);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        try {
            handleDelete(id).then(response => {
                if (!response.ok) {
                    MessageBox({
                        type: "error",
                        text: "bad request"
                    });
                    window.location.reload();
                }
                if (response.ok) {
                    setRows(rows.filter((row) => row.id !== id));
                    MessageBox({
                        type: "success",
                        text: "Data saved successfully"
                    });
                }
            });

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        try {
            handleSave(updatedRow).then(response => {
                    if (!response.ok) {
                        MessageBox({
                            type: "error",
                            text: "bad request"
                        });
                        window.location.reload();
                    }
                    if (response.ok) {
                        MessageBox({
                            type: "success",
                            text: "Data saved successfully"
                        });
                    }

                }
            );

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    const handleSave = async (params) => {

        console.log(params)
        return await updateWaitingList(params);

    }

    const columns = [
        {
            field: 'username',
            headerName: 'Username',
            valueGetter: (params) => params.row.username || '',
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',

            valueGetter: (params) => params.row.email || '',
            editable: true,
        },

        {
            field: 'dedicated_fan',
            headerName: 'DedicatedFan',
            type: 'boolean',
            valueGetter: (params) => params.row.dedicated_fan || false,

        },
        {
            field: 'idea_is_private',
            headerName: 'privacy',
            type: 'boolean',
            valueGetter: (params) => params.row.idea_is_private || false,
        },
        {
            field: 'community',
            headerName: 'Community',
            valueGetter: (params) => params.row.community || '',
            editable: true,
        },
        {
            field: 'idea',
            headerName: 'Idea',
            valueGetter: (params) => params.row.idea || '',
            editable: true,
        },
        {
            field: 'found_us_through',
            headerName: 'found',
            valueGetter: (params) => params.row.found_us_through || '',
            editable: true,
        },

        {
            field: 'price_range',
            headerName: 'Spending Budget',
            valueGetter: (params) => params.row.price_range || '',
            editable: true,
        },
        {
            field: 'projectDescription',
            headerName: 'ProjectInfo',
            valueGetter: (params) => params.row.projectDescription || '',
            editable: true,
        },
        {
            field: 'discord',
            headerName: 'Discord',
            valueGetter: (params) => params.row.discord || '',
            editable: true,
        },
        {
            field: 'linkedin',
            headerName: 'Linkedin',
            valueGetter: (params) => params.row.linkedin || '',
            editable: true,
        },
        {
            field: 'instagram',
            headerName: 'Instagram',
            valueGetter: (params) => params.row.instagram || '',
            editable: true,
        },
        {
            field: 'twitter',
            headerName: 'Twitter',
            valueGetter: (params) => params.row.twitter || '',
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        },


    ];

    const handleDelete = async (id) => {

        console.log('Deleting user with ID:', id);
        return await fetch(`${baseurl}/removeFromWaitingList`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "waitingListID": id,
            }),
        });


    };


    return (
        <div className='waitinglistadmin'>
            {Sidebarsdata}
            <div style={{minHeight: '100px', width: '84%', marginTop: '40px', float: 'right'}}>
                <Grid>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        experimentalFeatures={{columnGrouping: true}}
                        columnGroupingModel={[
                            {
                                groupId: 'Userinfo',
                                children: [{
                                    field: 'username',
                                    headerName: 'Username',
                                    valueGetter: (params) => params.row.username || '',
                                    editable: true,
                                },
                                    {
                                        field: 'email',
                                        headerName: 'Email',

                                        valueGetter: (params) => params.row.email || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'idea_is_private',
                                        headerName: 'privacy',
                                        type: 'boolean',
                                        valueGetter: (params) => params.row.idea_is_private || false,
                                    },
                                    {
                                        field: 'dedicated_fan',
                                        headerName: 'DedicatedFan',
                                        type: 'boolean',
                                        valueGetter: (params) => params.row.dedicated_fan || false,

                                    },

                                ]
                            },
                            {
                                groupId: 'others',
                                children: [
                                    {
                                        field: 'idea',
                                        headerName: 'Idea',
                                        valueGetter: (params) => params.row.idea || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'community',
                                        headerName: 'Community',
                                        valueGetter: (params) => params.row.community || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'found_us_through',
                                        headerName: 'found',
                                        valueGetter: (params) => params.row.found_us_through || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'price_range',
                                        headerName: 'Spending Budget',
                                        valueGetter: (params) => params.row.price_range || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'projectDescription',
                                        headerName: 'ProjectInfo',
                                        valueGetter: (params) => params.row.projectDescription || '',
                                        editable: true,
                                    }
                                ]
                            },
                            {
                                groupId: 'social media',
                                children: [
                                    {
                                        field: 'discord',
                                        headerName: 'Discord',
                                        valueGetter: (params) => params.row.discord || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'linkedin',
                                        headerName: 'Linkedin',
                                        valueGetter: (params) => params.row.linkedin || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'instagram',
                                        headerName: 'Instagram',
                                        valueGetter: (params) => params.row.instagram || '',
                                        editable: true,
                                    },
                                    {
                                        field: 'twitter',
                                        headerName: 'Twitter',
                                        valueGetter: (params) => params.row.twitter || '',
                                        editable: true,
                                    },
                                ]
                            }
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 13},
                            },
                        }}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        pageSizeOptions={[13]}
                        checkboxSelection
                        rowKey={(row) => row.id}

                    />
                </Grid>

            </div>
        </div>
    );
}
