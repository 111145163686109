import React, {useState} from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBCardFooter,

}
    from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../signin.css'
import MessageBox from "../../messageBox/MessageBox";
import {useNavigate} from "react-router-dom";
import baseurl from "../../../settings/urlsettings";
import Modal from "react-bootstrap/Modal";
import PloginLogins from "./ploginLogins";
import {CloseButton} from "react-bootstrap";


function StandaredLoginPopUp(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();


    const submit = async (e) => {
        e.preventDefault();
        await fetch(baseurl + '/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: "include",
            body: JSON.stringify({
                email,
                password
            })
        }).then(async response => {
            if (response.ok) {
                if (props.returnPath)
                    navigate(props.returnPath)
                else
                    navigate("/developerDashboard")
                window.location.reload();

            } else if (response.status === 400) {
                MessageBox({
                    type: "error",
                    text: "Your Email or your password in incorrect"
                });

            } else if (response.status === 404) {
                MessageBox({
                    type: "error",
                    text: "Your Email or your password in incorrect"
                });


            } else {
                MessageBox({
                    type: "error",
                    text: "An error occurred"
                });
            }
        })
            .catch(error => {
                MessageBox({
                    type: "error",
                    text: "A network error occurred"
                });

            })

    }

    return (
        <Modal  show={props.show} onHide={props.hide} backdrop="static" contentClassName={"containerlogin bg-dark"}>
            <div>
                {
                    !props.returnPath &&
                    <CloseButton className={'ModelCloseButton'} variant={"white"} onClick={ props.hide}/>

                }

            </div>
            <Modal.Body className={'flex-center p-0 '}>
                <MDBCard id={"login_card"}
                         className='bg-dark text-white'>
                    <MDBCardBody className=' d-flex flex-column align-items-center '>
                        <h2 className="messss fw-bold mb-2 text-uppercase">
                            Login
                        </h2>
                        <p className="text-white-50 mb-5">
                            Please enter your email and password!
                        </p>

                        <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                  labelClass='text-white'
                                  label='Email address'
                                  id='formControlLg' type='email'
                                  size="lg"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                        />
                        <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                  labelClass='text-white'
                                  label='Password'
                                  id='formControlLg'
                                  type='password'
                                  size="lg"
                                  value={password}
                                  onChange={e => setPassword(e.target.value)}
                        />

                        <p className="small mb-3 pb-lg-2">
                            <a className="text-white-50" href="/forgetpassword">
                                Forgot password?
                            </a>
                        </p>
                        <MDBBtn outline className='dsali mx-2 px-5'
                                color='white'
                                size='lg'
                                type="submit"
                                onClick={submit}
                        >
                            Login
                        </MDBBtn>

                        {/*<div>*/}
                        {/*    <p className="jesss mb-0">*/}
                        {/*        Don't have an account?*/}

                        {/*    </p>*/}

                        {/*    <MDBBtn outline color='white' className="text-white-50 fw-bold" onClick={props.signup}*/}
                        {/*    >*/}
                        {/*        Sign up*/}
                        {/*    </MDBBtn>*/}


                        {/*</div>*/}
                    </MDBCardBody>
                    <MDBCardFooter >
                        {/*<br/>*/}
                        {/*<PloginLogins show={props.show} onHide={props.hide} returnPath = {props.returnPath}/>*/}
                        {/*<br/>*/}
                    </MDBCardFooter>

                </MDBCard>

            </Modal.Body>
        </Modal>


    )
        ;

}

export default StandaredLoginPopUp;

