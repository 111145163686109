import React, {useEffect, useState} from "react";
import "./waitinglist.css";
import {Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import MessageBox from "../messageBox/MessageBox";
import {ImageTilte} from "../basic/ImageWithText";
import baseurl from "../../settings/urlsettings";
import {MDBCheckbox} from "mdb-react-ui-kit";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PopUpAuth from "../authentication/PopUpComponents/PopUpAuth";
import authenticationPage from "../authentication/PopUpComponents/authenticationPage";
import AuthenticationPage from "../authentication/PopUpComponents/authenticationPage";


const flatted = require('flatted');


export default function WaitinglistForm(props) {

    const navigate = useNavigate();
    const [name, setName] = useState(props.user?.name);
    const [email, setEmail] = useState(props.user?.email);
    const [promo, setPromo] = useState("");
    const [ispromo, setIspromo] = useState(false);
    // const [data, setData] = useState(undefined);
    const [discord, setDiscord] = useState(undefined);
    const [twitter, setTwitter] = useState(undefined);
    const [instagram, setInstagram] = useState(undefined);
    const [linkedin, setLinkedin] = useState(undefined);
    const [idea, setIdea] = useState("");
    const [bool3d, setBool3d] = useState(false);
    const [discription3d, setDiscription3d] = useState("");
    const [comunity, setComunity] = useState("");
    const [boolfanbase, setBoolfanbase] = useState(false);
    const [discriptionfan, setDiscriptionfan] = useState("");
    const [monay, setMoany] = useState("$0-$50");
    const [privacy, setPrivacy] = useState(false);
    const [hear, setHear] = useState("Social Media");
    const [others, setOthers] = useState("");
    const [organizename, setOrganizename] = useState("");
    const monayRange = ["$0-$50", "$50-$100", "$100-$500", "$500-$5000", "more than $5000"];
    const hearoption = ["Social Media ", "Word of Mouth/Referral",
        " Online Advertisement", " MorseVerse Website", " Virtual Conference/Event",
        "Other"
    ];
    const [policy, setPolicy] = useState(false);
    const [validated, setValidated] = useState(false);

    function onClickSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        console.log(props.loginProps.wallet?.accounts[0])
        setValidated(true);
        event.preventDefault();
        var data = {
            "username": name,
            "email": email,
            "linkedin": linkedin,
            "twitter": twitter,
            "instagram": instagram,
            "discord": discord,
            "about_my_idea": idea,
            "idea_is_private": privacy,
            "price_range": monay,
            "found_us_through": hear === "Other" ? others : hear,
            "community": comunity,
            "wallet": props.loginProps.wallet?.accounts[0]?.address.toString()
        };
        if (boolfanbase) {
            data["dedicated_fan"] = discriptionfan;
        }
        if (bool3d) {
            data["projectDescription"] = discription3d;

        }
        if (organizename !== "") {
            data["OrganizationName"] = organizename;
        }
        if(ispromo){
            data["promo"] = true;
        }

        
        console.log(data)
        fetch(baseurl + "/addToWaitingList", {

            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(data)
        }).then(async response => {
            if (response.ok) {
                MessageBox({
                    text: "successful update",
                    type: "success"
                });
                navigate("/waitlistinfo");

                // window.location.reload();
            } else {
                MessageBox({
                    text: "please try later ",
                    type: "error"
                });

            }
        });

    }
function onClickSubmits() {
    console.log({"promo": parseInt(promo),
    "userid": props.user?.id});
    fetch(baseurl + "/promoVerification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({"userid": props.user?.id,"promo": parseInt(promo)
        }) ,
    }).then(async response => {
        if (response.ok) {
            setIspromo(true);
            MessageBox({
                text: "promo validated",
                type: "success"
            });
        } else {
            MessageBox({
                text: "invalid promo",
                type: "error"
            });

        }
    });
    
}
useEffect(() => {
        setDiscord(props.user?.discord);
        setTwitter(props.user?.twitter);
        setInstagram(props.user?.instagram);
        setLinkedin(props.user?.linkedin);
    }, [props.user]);

    return (
        <div className="waitlistform">
            { props.user==="" &&
                <AuthenticationPage user={props.user} setUser={props.setUser} facebook={props.facebook}
                                    google={props.google} returnPath={"/waitlistform"}/>
            }

            <div className="deffines">


            </div>

            <Container className={"waitlistInfo waitlistInfo1"}>
                <Row>
                    <Col>
                        <h1 className="waitlistTitle1">Waiting list form </h1>
                    </Col>

                </Row>

                <Form className="waitinglistform" onSubmit={onClickSubmit}>
                    <Form.Group controlId="username">
                        <Row>
                            <Col>
                                <Form.Label className="form-labelss">Username :</Form.Label>
                                <Form.Control type="text" value={props.user?.name}
                                              onChange={e => setName(e.target.value)}/>
                            </Col>
                            <Col>
                                <Form.Label className="form-labelss">Email :</Form.Label>
                                <Form.Control type="email" value={props.user?.email}
                                              onChange={e => setEmail(e.target.value)}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group controlId="socialMedia">
                        <Form.Label className="waitform-label"> please provide your
                            socialMedia
                            information: </Form.Label>
                        <Row>
                            <Col>
                                <ImageTilte src={require("../../assets/dashboard/twitter-png-5964.png")}
                                            title={"Twitter "}
                                            alt={"TwitterLogo"}/>
                                <InputGroup>
                                    <InputGroup.Text>https://twitter.com/</InputGroup.Text>
                                    <Form.Control
                                        placeholder="theMorseVerse"
                                        value={`${twitter}`}
                                        onChange={e => setTwitter(e.target.value)}/>
                                </InputGroup>

                            </Col>
                            <Col>
                                <ImageTilte src={require("../../assets/dashboard/Instagram-logo-transparent-PNG.png")}
                                            title={"Instagram"}
                                            alt={"instagrmLogo"}/>
                                <InputGroup>
                                    <InputGroup.Text>https://www.instagram.com/</InputGroup.Text>
                                    <Form.Control
                                        placeholder="themorseverse/"
                                        value={instagram}
                                        onChange={e => setInstagram(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ImageTilte src={require("../../assets/dashboard/discord.png")}
                                            title={"Discord "}
                                            alt={"discordLogo"}/>
                                <InputGroup>
                                    <InputGroup.Text>https://discord.com/server/</InputGroup.Text>
                                    <Form.Control
                                        placeholder="invite/zZE9CUAKpb"
                                        value={discord}
                                        onChange={e => setDiscord(e.target.value)}/>
                                </InputGroup>
                            </Col>
                            <Col>
                                <ImageTilte src={require("../../assets/dashboard/official-linkedin-icon-png-7.jpg")}
                                            title={"Linkedin "}
                                            alt={"linkedinLogo"}/>
                                <InputGroup>
                                    <InputGroup.Text>https://www.linkedin.com/</InputGroup.Text>
                                    <Form.Control
                                        placeholder="company/morseverse/"
                                        value={linkedin}
                                        onChange={e => setLinkedin(e.target.value)}/>
                                </InputGroup>
                            </Col>
                        </Row>

                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="form-labelss">Organization name :</Form.Label>
                        <Form.Control type="text" value={organizename} onChange={e => setOrganizename(e.target.value)}/>
                    </Form.Group>

                    <Form.Group controlId="fanbase">

                        <Form.Check type="checkbox"
                                    style={{padding: "7.5px 0px 0px 1.5rem"}}
                                    label="Do you have a dedicated fan base or following that would be interested in your MorseVerse project ? "
                                    checked={boolfanbase}
                                    onChange={e => setBoolfanbase(!boolfanbase)}/>
                        {boolfanbase && <>
                            <Form.Label className="waitform-label"> please provide details and links (max 100
                                words)</Form.Label>
                            <Form.Control
                                rows="2"
                                placeholder={"my fan base is on  ... "}
                                maxLength="100"
                                as="textarea"
                                value={discriptionfan}
                                onChange={e => setDiscriptionfan(e.target.value)}/>
                            <div className="form-item-socialMedia-charactercount">
                                {discriptionfan?.length} / 100
                            </div>

                        </>}
                    </Form.Group>

                    <Form.Group controlId="Idea">
                        <Form.Label className="waitform-label">Briefly describe your idea or project for the land you
                            would like to
                            acquire in
                            MorseVerse (max 200 words)* :</Form.Label>
                        <Form.Control
                            required
                            placeholder="my Idea is ..." rows="4"
                            maxLength="200"
                            as="textarea"
                            value={idea}

                            onChange={e => setIdea(e.target.value)}/>
                        <div className="form-item-socialMedia-charactercount">
                            {idea?.length} / 200
                        </div>
                    </Form.Group>
                    <Form.Group controlId={"privacy"}>
                        <Form.Check type="checkbox"
                                    label="Please tick this box if you would like to keep your idea private. By selecting this option, your idea will not be displayed on the MorseVerse Waiting List on the website. Instead, our team will carefully evaluate your submission and assign it a rank based on its merit, considering other applications. We understand the importance of privacy and assure you that your idea will be handled with utmost confidentiality during the evaluation process. Your privacy is important to us, and we value your trust in sharing your innovative concept with us."
                                    checked={privacy}
                                    onChange={e => setPrivacy(!privacy)}/>
                    </Form.Group>


                    <Form.Group controlId={"comunity"}>
                        <Form.Label className="waitform-label">
                            How do you plan to engage and involve the MorseVerse community in your project? (max 200
                            words)* :
                        </Form.Label>
                        <Form.Control
                            required
                            placeholder="my Idea is ..." rows="2"
                            maxLength="200"
                            as="textarea"
                            value={comunity}
                            onChange={e => setComunity(e.target.value)}/>
                        <div className="form-item-socialMedia-charactercount">
                            {comunity?.length} / 200
                        </div>
                    </Form.Group>
                    <Form.Group controlId="model3d">
                        <Form.Check type="checkbox" label="Do you have an existing 3D model or Unity project that you would like to
                            incorporate into your MorseVerse project ? " checked={bool3d}
                                    onChange={e => setBool3d(!bool3d)}/>
                        {bool3d && <>
                            <Form.Label className="waitform-label"> please provide additional details about the 3D model
                                or Unity
                                project (max
                                100 words) :</Form.Label>
                            <Form.Control
                                rows="4"
                                placeholder={"my project/3d model is ... "}
                                maxLength="100"
                                as="textarea"
                                value={discription3d}
                                onChange={e => setDiscription3d(e.target.value)}/>
                            <div className="form-item-socialMedia-charactercount">
                                {discription3d?.length} / 100
                            </div>
                            {/*<Form.Label> Please upload pictures or screenshots showcasing your project.</Form.Label>*/}
                            {/*<Form.Control multiple type="file"/>*/}

                        </>}

                    </Form.Group>

                    <Form.Group controlId={"investment"}>
                        <Form.Label className="waitform-label">
                            What price range are you willing to pay for acquiring land in MorseVerse ?
                        </Form.Label>
                        <Form.Select onChange={event => setMoany(event.target.value)}>
                            {monayRange.map((e) => {
                                return (<option value={e}
                                >{e} </option>);

                            })}
                        </Form.Select>

                    </Form.Group>
                    <Form.Group controlId={"advertisement"}>
                        <Form.Label className="waitform-label">
                            How did you hear about MorseVerse ?
                        </Form.Label>
                        <Form.Select onChange={event => setHear(event.target.value)}>
                            {hearoption.map((e, i) => {
                                return (<option value={e} key={i}>
                                    {e} </option>);

                            })}
                        </Form.Select>
                        {hear === "Other" &&
                            <>
                                <Form.Label>
                                    Please specify how did you hear about MorseVerse ?
                                </Form.Label>
                                <Form.Control type="text" value={others} onChange={e => setOthers(e.target.value)}/>
                            </>

                        }
                        <Form.Group controlId="promo">
                        <Row>
                            <Col>
                                <Form.Label className="form-labelss form_promolabel">Promo Code:</Form.Label>
                                    <Form.Control
                                    type="number"
                                    value={promo}
                                    placeholder={"Promo code"}
                                    maxLength="6"
                                    onChange={(e) => setPromo(e.target.value)}
                                    />
                            </Col>
                            <Col className="buttom-promos">
                                <Button onClick={onClickSubmits} className="button-promo"> validate </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="waitform-label">
                            By submitting this form, you agree to comply with MorseVerse's terms and conditions and
                            understand that the selection process for acquiring land will be based on the quality and
                            feasibility of your project idea, community engagement, and potential collaboration
                            opportunities.
                        </Form.Label>
                        <InputGroup style={{padding: "10px 0px"}}>
                            <MDBCheckbox name="flexCheck"
                                         required
                                         onChange={e => setPolicy(e.target.checked)}
                                         id="flexCheckDefault"/>
                            <Link to="/Privacypolicy">
                                I Agree to the terms and condition
                            </Link>
                        </InputGroup>
                    </Form.Group>
                    <Button style={{margin: "10px 0px"}} variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>


            </Container>
        </div>
    );
}


// function LoginWallet({loginProps}) {
//     const {
//         wallet,
//         connecting,
//         connect,
//         disconnect,
//         ethersProvider,
//         connected,
//         recipient,
//         amount,
//         handleConnect,
//         handleDisconnect,
//     } = loginProps;
//
//     const [show, setShow] = useState(true);
//
//     const handleClose = () => {
//         if (connected || connecting)
//             setShow(false)
//     };
//     const handleShow = () => setShow(true);
//
//     return (
//         <>
//             <Modal show={show} onHide={handleClose}>
//                 <h2 className='headers'>Welcome back</h2>
//                 <form>
//                     <Modal.Body> In order to continue you need to login </Modal.Body>
//                     {
//                         !connected ?
//                             <button className='buttonses' disabled={connecting} onClick={() => {
//                                 setShow(false)
//                                 if (connected) {
//                                     handleDisconnect().then(handleShow);
//
//                                 } else {
//                                     handleConnect().then(handleShow);
//                                 }
//                                 handleClose();
//                             }}>
//                                 {connecting ? 'Connecting' : connected ? 'Disconnect' : 'Connect your wallet'}
//                             </button>
//                             :
//                             handleClose()
//
//                     }
//
//                 </form>
//             </Modal>
//         </>
//     );
// }

