import React from 'react';
import "./blogmorse.css"
import {blogData} from "./blogData";
import {Col, Container, Row} from "react-bootstrap";

const BlogPost = ({author, title, link, description, tags}) => {
    return (
        <div className="links-mea">
            <p className="nameblog">{author}</p>
            <a href={link} className="linkblog">{title}</a>
            <p className="descblog">{description}</p>
            {tags.map((tag, index) => (
                <a key={index}>
                    <button className={`tagsblog`}>{tag}</button>
                </a>
            ))}
        </div>
    );
};
export const Blogmorse = [
    <div className="blogmorseme">

        <Container>
            <Row>
                <Col lg={3}  className="d-none d-lg-block">
                    <h2 className="h2-blogss">Discorver more</h2>
                    <div className="discover_me">
                        <a>
                            <button className="tagsblog2">Artificial intelligence</button>
                        </a>
                        <a>
                            <button className="tagsblog2">AI_agent</button>
                        </a>
                        <a>
                            <button className="tagsblog2">Costumer service</button>
                        </a>
                        <a>
                            <button className="tagsblog2">Automation</button>
                        </a>
                        <a>
                            <button className="tagsblog2">Data_analysis</button>
                        </a>
                    </div>
                </Col>

                <Col lg={9} md={12} sm={12} className="flexs-mes">
                    <h2 className="blogmorse">MorseBlog</h2>
                    <Container>
                        <Row>
                            {blogData.map((blog, index) => (
                                <Col key={index} xs={12} sm={12} md={6} lg={6} className="d-flex align-items-stretch">
                                    <BlogPost
                                        author={blog.author}
                                        title={blog.title}
                                        link={blog.link}
                                        description={blog.preview}
                                        tags={blog.tags}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>


    </div>
]