import validator from 'validator';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';

function Login({ loginProps }) {
  const {
    wallet,
    connecting,
    connect,
    disconnect,
    ethersProvider,
    connected,
    recipient,
    amount,
    handleConnect,
    handleDisconnect,
  } = loginProps;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleclick=()=>{
    window.location.replace('https://www.morseverse.com/environment');
  }
  return (
    <>
      <img className='img-log' src={require('../../assets/images/cnnectyouraccount copy1.png')}></img><button onClick={handleShow} type="button" className='butt-log'><div className='changed-mes'><p className='p-buton-log1'>CONNECT YOUR ACCOUNT</p>

</div></button>
      <Modal show={show} onHide={handleClose}>
        <img className='imgs' src={require('../../assets/images/logo.jpg')}/>
        <button onClick={handleClose} className='btn-closee'> <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></button>
        <h2 className='headers'>Welcome back</h2>
        <form>
        <Modal.Body>Choose one of the methodes below</Modal.Body>
        {/* <input className='inputs' type={'text'} placeholder={'email'} autofocus></input> */}


        {/* <button className="buttonses" type="button">Connect your wallet</button> */}
        {
        !connected ? 
          <button className='buttonses' disabled={connecting} onClick={() => {
            if (connected) {
              handleDisconnect().then(handleShow);
              
            } else {
              handleConnect().then(handleShow);
            }
            handleClose ();
          }}>
            {connecting ? 'Connecting' : connected ? 'Disconnect' : 'Connect your wallet'}
          </button>
        :
        
        <a href={"#"}  onClick={handleclick}><button className='buttonses' type='button'>Launch MetaVerse</button></a>
      }



        <h6 className='h6-log'>OR LOGIN WITH EMAIL</h6>
          <Modal.Footer className='modidi'>
          <a className='a-bt' href='/signin'><button className='buttonsse' type='button'>Login</button></a>
          <div className='signup-opti'>
                                    <p className="jesss mb-0">
                                        Don't have an account?
                                        <a href="/Singup"
                                           class="text-black-50 fw-bold">
                                            Sign Up
                                        </a>
                                    </p>

                                </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Login;