import "./developerDashbord.css"
import React, {useState, useMemo} from 'react';
import {Container, Row, Col, Badge, Button} from 'react-bootstrap';

const Conversations = (
    {
        conversation=[],
        time,
        qcount
    }
) => {
    const pageSize = 1;  // Number of user groups per page
    const [itemsDisplayed, setItemsDisplayed] = useState(2);
    const groupedByUser = useMemo(() => {
        return conversation.reduce((acc, entry) => {
            acc[entry.userId] = acc[entry.userId] || [];
            acc[entry.userId].push(entry);
            return acc;
        }, {});
    }, [conversation]);

    const userGroups = useMemo(() => Object.entries(groupedByUser), [groupedByUser]);
    const visibleData = useMemo(() => userGroups.slice(0, itemsDisplayed * pageSize), [userGroups, itemsDisplayed, pageSize]);

    const handleShowMore = () => {
        if (itemsDisplayed * pageSize < userGroups.length) {
            setItemsDisplayed(itemsDisplayed + 1);
        }
    };
    return (
        <Container className="conversion">
            <Row>
                <Col md={3} lg={3} sm={2}>
                    <Row className={"empty_space_conversation"}/>
                    <Row className={"conversation_summary"}>
                        <p>
                            <strong>
                                Total Questions count
                            </strong>
                        </p>
                        <p>
                            {qcount}
                        </p>
                        <p>
                            <strong>
                                Total Time spent
                            </strong>
                        </p>
                        <p>
                            {time}
                        </p>

                    </Row>
                </Col>
                <Col>
                    <Row className="conversion_title">
                        Your Agent's Conversation Records
                    </Row>
                    <Row className="conversion_body">
                        {visibleData.map(([userId, entries], index) => (
                            <div key={index} className="user-conversations">
                                <Badge pill bg="secondary">User ID: {userId}</Badge>
                                {entries.map((entry, idx) => (
                                    <div key={idx} className="conversation-entry">
                                        <Row>
                                            <Col>
                                                <Badge pill bg="secondary">Posted
                                                    on: {new Date(entry.time).toLocaleString()}</Badge>
                                            </Col>
                                        </Row>
                                        <p><strong>Customer:</strong> {entry.question}</p>
                                        <p><strong>AI Customer Service:</strong> {entry.answer}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-center">
                            <Button onClick={handleShowMore} disabled={itemsDisplayed * pageSize >= userGroups.length}>Show
                                More</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={2} lg={2} sm={1}/>
            </Row>
        </Container>
    );


};


export default Conversations;