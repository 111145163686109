import {Col, Container, Row} from "react-bootstrap";
import button from "bootstrap/js/src/button";

const ImageWithText = (props) => {
    return (
        <Container style={{textAlign: "left"}}>
            <Row>

                <Col>
                    <img
                        width={50}
                        height={50}
                        alt={props.alt}
                        src={props.src}
                    />

                    &nbsp; {props.title}:&nbsp;
                    {props.text}

                </Col>

            </Row>
        </Container>
    );
}
export const ImageTilte = (props) => {
    return (
        <Container style={{textAlign: "left"}}>
            <Row>

                <Col style={{color: "black", padding:" 7.5px 0px"}}>
                    <img
                        width={30}
                        height={30}
                        alt={props.alt}
                        src={props.src}
                    />

                    &nbsp; {props.title}:&nbsp;
                    {props.text}

                </Col>

            </Row>
        </Container>
    );
}
export default ImageWithText