import baseurl from "../../settings/urlsettings.js";
import MessageBox from "../messageBox/MessageBox";
import axios from "axios";

// Function to update user data
export const updateUser = async (userData) => {
    try {
        console.log('Updating user:', userData);
        delete userData.is_verified;
        delete userData.isNew;
        return await fetch(`${baseurl}/editUser`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "userID": userData.id,
                "updates": userData
            }),
        });


    } catch (error) {
        console.error('Error updating user:', error);
        return null;
    }
};

// Function to fetch user list data
export const fetchUserlistData = async () => {
    try {
        const response = await fetch(`${baseurl}/users`);
        if (!response.ok) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

        const userListData = await response.json();
        return userListData.map((user) => ({
            id: user.id,
            email: user.email,
            name: user.name,
            date : user.date,
            is_verified: user.is_verified,
            about: user.about,
            discord: user.discord,
            linkedin: user.linkedin,
            instagram: user.instagram,
            twitter: user.twitter,
        }));
    } catch (error) {
        console.error('Error fetching user list data:', error);
        return [];
    }
};

export const fetchSubscribelistData = async () => {
    try {
        const response = await axios.get(baseurl + '/people');
        // console.log(response);
        return response.data.map((subscriber) => ({
            id: subscriber._id,
            email: subscriber.email,
            date: subscriber.date

        }));

    } catch (error) {
        console.error('Error fetching waitlist data:', error);
    }
};
export const updateSubscription = async (userData) => {
    try {
        console.log('Updating user:', userData.email);
        delete userData.isNew;
        return await fetch(`${baseurl}/person_edit`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "subscriptionID": userData.id,
                "updates": {
                    "email": userData.email
                }
            }),
        });


    } catch (error) {
        console.error('Error updating user:', error);
        return null;
    }
};


export const updateWaitingList = async (userData) => {
    try {
        console.log('Updating info:', userData);
        delete userData.idea_is_private;
        delete userData.dedicated_fan;
        delete userData.isNew;

        const response = await fetch(`${baseurl}/editWaitingListEntry`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "waitingListID": userData.id,
                "updates": userData
            }),
        });

        console.log('API Response:', response);

        if (!response.ok) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

        return response;
    } catch (error) {
        console.error('Error updating user:', error);
        return null;
    }
};
export const fetchWaitlistData = async () => {

    const response = await axios.get(baseurl + '/getWaitingListsSortedByLikes');
    return response.data.map((entry) => ({
        id: entry._id,
        username: entry.username,
        email: entry.email,
        idea: entry.about_my_idea,
        community: entry.community,
        dedicated_fan: entry.dedicated_fan,
        found_us_through: entry.found_us_through,
        idea_is_private: entry.idea_is_private,
        price_range: entry.price_range,
        projectDescription: entry.projectDescription,
        discord: entry.discord,
        linkedin: entry.linkedin,
        instagram: entry.instagram,
        twitter: entry.twitter,
    }));

};

const AllMonthsSelector = ({ selectedMonth, setSelectedMonth }) => {
  return (
    <select
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(Number(e.target.value))}
    >
      <option value={null}>All Months</option>
      {Array.from({ length: 12 }, (_, index) => (
        <option key={index} value={index}>
          {new Date(0, index).toLocaleDateString("en-US", { month: "long" })}
        </option>
      ))}
    </select>
  );
};

export default AllMonthsSelector;