import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Table,Pagination  ,ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import "./developerDashbord.css"
import CircularProgress from '@mui/joy/CircularProgress';


const YourAIComponent = (
    {
        ai_link,
        chatEnabled,
        setChatEnabled,
        creative,
        setCreative,
        unknown,
        setUnknown,
        tableData,
        hasChanged,
        setHasChanged
    }
) => {


    const handleChatChange = (value) => {
        setChatEnabled(value);
        setHasChanged(true);
    };

    const handleCreativeChange = (value) => {
        setCreative(value);
        setHasChanged(true);
    };

    const handleUnknownChange = (value) => {
        setUnknown(value);
        setHasChanged(true);
    };

    return (
        <>
            <Form>
                <Form.Group controlId="aiAgentLink" className={"ai_link"}>
                    <Form.Label>Your AI-agent's link</Form.Label>
                    <Row>
                        <Col/>
                        <Col ms={10} md={9} lg={9}>
                            <Form.Control type="text" value={ai_link} placeholder="your AI-agent link"/>
                        </Col>
                        <Col/>
                    </Row>
                </Form.Group>
                <Form.Group className={"sensibility"}>
                    <Row>
                        <Col lg={2} md={2} sm={2}/>
                        <Col>
                            <Row>
                                <Form.Group controlId="chatToggle">
                                    <Row className={"justify-content-start"}>
                                        <Col md={10} lg={9}>
                                            <p>Enable chatting option</p>
                                        </Col>
                                        <Col md={2} lg={3}>
                                            <Form.Check
                                                type="switch"
                                                checked={chatEnabled}
                                                onChange={(e) =>
                                                    handleChatChange(e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md={10} lg={9}>Sensitivity:</Col>
                                <Col md={2} lg={3}/>

                            </Row>

                            <Row>

                                <Col md={10} lg={9}>
                                    <p>
                                        I want my AI agent to be creative when it wants to answer a question which is
                                        not available on my documents
                                    </p>
                                </Col>
                                <Col md={2} lg={3}>
                                    <Form.Check
                                        type="switch"
                                        checked={creative}
                                        onChange={(e) =>
                                            handleCreativeChange(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} lg={9}>
                                    <p>
                                        I want my AI agent to not answer any question which is not available in my
                                        documents
                                    </p>
                                </Col>
                                <Col md={2} lg={3}>
                                    <Form.Check
                                        type="switch"
                                        checked={unknown}
                                        onChange={(e) =>
                                            handleUnknownChange(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Form.Group>

            </Form>
            <div className={"tlb_ai_order"}>
                <CustomTable data={tableData}/>
            </div>
        </>
    );
};


function CustomTable({ data = [] }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3; // Number of items per page

    // Calculate the current items to display based on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Table striped bordered hover variant={"dark"}>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Progress Status</th>
                    <th>Progress</th>
                </tr>
                </thead>
                <tbody>
                {currentItems.map((item, index) => (
                    <tr key={index}>
                        <td>{item.title}</td>
                        <td>{new Date(item.date).toLocaleDateString()} {new Date(item.date).toLocaleTimeString()}</td>
                        <td>{item.status}</td>
                        <td className="progress-cell">
                            <CircularProgress size="lg" color="success" determinate value={item.progress}>
                                <span className={"progress-bar-txt"}> {item.progress}% </span>
                            </CircularProgress>

                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Pagination size={"sm"}  className={"pagination-custom"} >

                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />

            </Pagination>
        </>
    );
}

export default YourAIComponent;
