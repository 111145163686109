import React, {useEffect, useState} from 'react';
import {Container, Row, Col, ListGroup, Badge, Button} from 'react-bootstrap';

// Import your component modules
import YourAIComponent from './YourAIAgents';
import Information from './Information';
import Appearance from './Appearance';
import Conversations from './Conversations';
import UserManual from './UserManual';
import BugReport from './BugReport';
import './api_calls'
import {
    change_report,
    fetchAiSetting,
    fetchAppearance,
    fetchConversations,
    fetchgetAiList,
    fetchInformation, submitBugReport,
    updateAiSetting, updateAppearance, updateInformation
} from "./api_calls";
import MessageBox from "../../components/messageBox/MessageBox";


export default function DeveloperDashboard({user}) {
    const CompanyId = user ? user.company_id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [activeComponent, setActiveComponent] = useState('Your AI agents');
    const [agentUrl, setAgentUrl] = useState("https://www.google.com/");
    //Your AI agents
    const [chatEnabled, setChatEnabled] = useState(false);
    const [creative, setCreative] = useState(false);
    const [unknown, setUnknown] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [aiAgentChanged, setAiAgentChanged] = useState(false);
    //Information
    const [enterpriseName, setEnterpriseName] = useState("");
    const [website, setWebsite] = useState("");
    const [industry, setIndustry] = useState("");
    const [description, setDescription] = useState("");
    const [agentName, setAgentName] = useState("");
    const [commonInquiries, setCommonInquiries] = useState("");
    const [otherAdjustments, setOtherAdjustments] = useState("");
    const [language, setLanguage] = useState("English");
    const [selectedFile, setSelectedFile] = useState(null);
    const [documentationLinks, setDocumentationLinks] = useState([]);
    const [documentationlist, setDocumentationlist] = useState([]);
    const [referredLinks, setReferredLinks] = useState([]);
    const [infoChanged, setInfoChanged] = useState(false);
    //Appearance_components
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [appearanceChanged, setAppearanceChanged] = useState(false);
    //conversation
    const [conversation, setConversation] = useState([]);
    const [time, setTime] = useState(120);
    const [qcount, setQcount] = useState(12);
    //bug report
    const [reportDescription, setReportDescription] = useState("");
    const [steps, setSteps] = useState("");
    const [contact, setContact] = useState(false);
    const [bugReportChanged, setBugReportChanged] = useState(false);
    // Mapping items to their corresponding components
    const componentsMap = {
        'Your AI agents': YourAIComponent,
        'Information': Information,
        'Appearance': Appearance,
        'Conversations': Conversations,
        'User manual': UserManual,
        'Bug report': BugReport,
    };

    const propsMap = {
        'Your AI agents': {
            ai_link: agentUrl,
            chatEnabled: chatEnabled,
            setChatEnabled: setChatEnabled,
            creative: creative,
            setCreative: setCreative,
            unknown: unknown,
            setUnknown: setUnknown,
            tableData: tableData,
            hasChanged: aiAgentChanged,
            setHasChanged: setAiAgentChanged
        },
        'Information': {
            enterpriseName: enterpriseName,
            setEnterpriseName: setEnterpriseName,
            website: website,
            setWebsite: setWebsite,
            industry: industry,
            setIndustry: setIndustry,
            description: description,
            setDescription: setDescription,
            agentName: agentName,
            setAgentName: setAgentName,
            commonInquiries: commonInquiries,
            setCommonInquiries: setCommonInquiries,
            otherAdjustments: otherAdjustments,
            setOtherAdjustments: setOtherAdjustments,
            language: language,
            setLanguage: setLanguage,
            selectedFile: selectedFile,
            setSelectedFile: setSelectedFile,
            documentationLinks: documentationLinks,
            setDocumentationLinks: setDocumentationLinks,
            referredLinks: referredLinks,
            setReferredLinks: setReferredLinks,
            documentationlist: documentationlist,
            setDocumentationlist: setDocumentationlist,
            setHasChanged: setInfoChanged
        },
        'Appearance': {
            selectedOffice: selectedOffice,
            setSelectedOffice: setSelectedOffice,
            selectedCharacter: selectedCharacter,
            setSelectedCharacter: setSelectedCharacter,
            setHasChanged: setAppearanceChanged,
        },
        'Conversations': {
            conversation: conversation,
            time: time,
            qcount: qcount,
        },
        'User manual': {},
        'Bug report': {
            description: reportDescription,
            setDescription: setReportDescription,
            steps: steps,
            setSteps: setSteps,
            contact: contact,
            setContact: setContact,
            setHasChange: setBugReportChanged,
        },
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (activeComponent === 'Your AI agents') {
                    const data = await fetchAiSetting(CompanyId);
                    // Assuming data structure returned is suitable directly
                    setChatEnabled(data.chatEnabled);
                    setCreative(data.creative);
                    setUnknown(data.unknown);
                    setAgentUrl(data.url);

                    const tableData = await fetchgetAiList(CompanyId);
                    setTableData(tableData);

                    // Set more states as necessary
                } else if (activeComponent === 'Information') {
                    const data = await fetchInformation(CompanyId);
                    setEnterpriseName(data.enterpriseName);
                    setWebsite(data.website);
                    setIndustry(data.industry);
                    setDescription(data.description);
                    setAgentName(data.agentName);
                    setCommonInquiries(data.commonInquiries);
                    setOtherAdjustments(data.otherAdjustments);
                    setLanguage(data.language);
                    setDocumentationlist(data.documentationLinks);
                    setDocumentationLinks(data.documentationLinks.map(link => link.fileName));
                    setReferredLinks(data.referredLinks);

                } else if (activeComponent === 'Appearance') {
                    const data = await fetchAppearance(CompanyId);
                    setSelectedOffice(data.office);
                    setSelectedCharacter(data.character);
                } else if (activeComponent === 'Conversations') {
                    const data = await fetchConversations(CompanyId);
                    if (data.details)
                        setConversation(data.details);
                    setTime(data.totalTime);
                    setQcount(data.totalQuestions);
                }


            } catch (error) {
                console.log({error})
                MessageBox({
                    text: error.message,
                    type: "error"
                });
                console.log("bye")
            }
        };

        fetchData();
    }, [activeComponent]);

    const handleItemClick = (itemName) => {
        setActiveComponent(itemName);
    };
    const handleSubmit = async () => {
        setIsLoading(true);  // Start loading indication
        let changes = "";

        const handleApiCall = async (apiFunction, payload, successMessage, changeFlagSetter) => {
            try {
                await apiFunction(CompanyId, payload);
                changes += successMessage;
                changeFlagSetter(false);
                if (successMessage.length !== 0) {
                    MessageBox({
                        text: `${successMessage}`,
                        type: "success"
                    });
                }

            } catch (error) {
                MessageBox({
                    text: `Failed to ${successMessage}`,
                    type: "error"
                });
                console.error(`${successMessage} error:`, error);
            }
        };

        try {
            if (aiAgentChanged) {
                await handleApiCall(
                    updateAiSetting,
                    {
                        "chatEnabled": chatEnabled,
                        "creative": creative,
                        "unknown": unknown,
                        "url": agentUrl
                    },
                    "update agent setting,",
                    setAiAgentChanged
                );
            }
            if (infoChanged) {
                await handleApiCall(
                    updateInformation,
                    {
                        "enterpriseName": enterpriseName,
                        "website": website,
                        "industry": industry,
                        "description": description,
                        "agentName": agentName,
                        "commonInquiries": commonInquiries,
                        "otherAdjustments": otherAdjustments,
                        "language": language,
                        "selectedFile": selectedFile,
                        "documentationLinks": documentationlist,
                        "referredLinks": referredLinks
                    },
                    "update information,",
                    setInfoChanged
                );
            }
            if (appearanceChanged) {
                await handleApiCall(
                    updateAppearance,
                    {
                        "office": selectedOffice,
                        "character": selectedCharacter
                    },
                    "update appearance,",
                    setAppearanceChanged
                );
            }
            if (bugReportChanged) {
                await handleApiCall(
                    submitBugReport,
                    {
                        "reportDescription": reportDescription,
                        "steps": steps,
                        "contact": contact,
                        "companyId": CompanyId
                    },
                    "submit bug report,",
                    setBugReportChanged
                );
            }
            if (changes.length !== 0) {
                await handleApiCall(
                    change_report,
                    {
                        "companyId": CompanyId,
                        "title": changes,
                        "date": new Date(),
                        "status": "submitted",
                        "statusClass": "submit",
                        "Progress": 0
                    },
                    "",
                    () => {
                    }
                );
            }


        } catch (error) {
            MessageBox({
                text: "Failed to submit data.",
                type: "error"
            });
            console.error("General submission error:", error);
        }

        setIsLoading(false);  // Stop loading indication
    };


    const ActiveComponent = componentsMap[activeComponent];
    const activeProps = propsMap[activeComponent];

    return (
        <Container fluid className="dashboard-container">
            <Row className={"main-content"}>
                <Col sm={9}>
                    <h2>Developer Dashboard</h2>
                    <Badge bg="primary" pill={true}>Specialized AI agents</Badge>
                </Col>
                <Col sm="3">
                    {
                        activeComponent === 'Your AI agents' && (

                            <iframe s src={agentUrl} title="AI Agent Description"></iframe>

                        )
                    }
                </Col>

            </Row>
            <Row>
                <Col md={3} className="sidebar">
                    <ListGroup variant="flush">
                        {Object.keys(componentsMap).map((itemName) => (
                            <ListGroup.Item
                                key={itemName}
                                active={activeComponent === itemName}
                                onClick={() => handleItemClick(itemName)}
                                style={{cursor: 'pointer'}}
                            >
                                {itemName}
                            </ListGroup.Item>
                        ))}
                        <ListGroup.Item>
                            <Button variant="primary" onClick={handleSubmit}>
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={9}>
                    <div className="content">
                        {/* Render the active component and spread the active props */}
                        <ActiveComponent {...activeProps} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
