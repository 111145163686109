import './css/footer.css'

import './css/responsive.css'
import './css/font-awesome.min.css'
import {FaXTwitter} from "react-icons/fa6";

function Footer_ex({setShowdemo}) {

    return (
        <div className='footer-component'>
            {/*     Fonts and icons     */}
            <link
                rel="stylesheet"
                type="text/css"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900|Roboto+Slab:400,700"
            />
            <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
                rel="stylesheet"
            />
            <meta charSet="utf-8"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>

            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="viewport" content="initial-scale=1, maximum-scale=1"/>

            <title>cla</title>
            <meta name="keywords" content/>
            <meta name="description" content/>
            <meta name="author" content/>

            {/* fevicon */}
            <link href="../../assets/img/brand/official final logo-min.png"

                  rel="icon" type="image/png"/>
            <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css"/>
            <footer>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">

                                <img className="logo1"
                                     src={require('../../assets/images/official logo transparent1.png')}
                                     alt="Morseverse metaverse logo"/>

                                <ul className="social_icon">
                                    <li>
                                        <a href="https://twitter.com/theMorseVerse" aria-hidden="true">
                                            <li className={"twitter_icon blas"}>
                                                <FaXTwitter className={"twitter_icon1"}/>
                                            </li>

                                        </a>
                                    </li>
                                    <li><a href="https://www.linkedin.com/company/morseverse/"><i
                                        className="fa fa-linkedin-square blas" aria-hidden="true"/></a></li>
                                    <li><a href="https://www.instagram.com/themorseverse/"><i
                                        className="fa fa-instagram blas" aria-hidden="true"/></a></li>
                                    <br/>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <h3 className='headmes'>Pages</h3>
                                <div className='flex-mess'>
                                    <div>
                                        <ul className="about_us">
                                            <li><a className="footer-links foot-links" href="/homepage">Home</a></li>
                                            <li><a className="footer-links foot-links" href='/blog'>Blog</a></li>
                                            <li><a className="footer-links foot-links" href='/terms'> Terms of
                                                Service</a></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className="about_us">


                                            <li><a className="footer-links foot-links" href="/PrivacyPolicy">Privacy
                                                Policy</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <h3 className='headmes'>Contact Us</h3>
                                <ul className="conta">
                                    <li>
                                        <a href="mailto:info@morseverse.com" target="_blank"
                                           className='foot-links'>Email</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">

                                <h3 className='headmes'>Get Started</h3>
                                <button value="submit"
                                        className="sub_btn"
                                        onClick={e => setShowdemo(true)}
                                >
                                    Sign Up for Beta
                                </button>

                            </div>

                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='copyrights'>© 2024 All Rights Reserved. Designed By MorseVerse.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer_ex;