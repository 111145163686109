import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow} from "mdb-react-ui-kit";
import {isValidEmail, isValidPassword} from "./register";
import MessageBox from "../messageBox/MessageBox";
import "./ResetPassword.css"
import {useLocation} from "react-router-dom";
import {type} from "@testing-library/user-event/dist/type";
import baseurl from "../../settings/urlsettings";

function ResetPassword() {
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);


    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }
    }, [location]);

    const disableButton = () => {
        return !!error;
    }
    const passwordHandler = (e) => {
        setError(isValidPassword(e.target.value))
        if (!error) {
            setPassword(e.target.value)
        }
    }
    const confirmPasswordHandler = (e) => {
        console.log(e.target.value)
        console.log(e.target.value === password)
        if (e.target.value === password ) {
            setConfirmPassword(e.target.value);
            setError(null)

        } else {
            setError('Passwords do not match');
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Replace the URL with your own API endpoint
            const response = await fetch(
                baseurl+'/reset-password',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({token, password}),
                });

            if (response.ok) {
                MessageBox({
                    text: 'Password reset successfully',
                    type: "success"
                });
            } else {
                const error = await response.json();
                MessageBox({
                    text: error.message,
                    type: "error"
                });

            }
        } catch (error) {
            MessageBox({
                text: error.message,
                type: "error"
            });

        }
    };

    return (
        <div className='bodyRestPassword'>
            <MDBContainer fluid>

                <MDBRow className='messs d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12' className=" py-5" id="forgetCard">

                        <MDBCard className='bg-dark text-white my-5 mx-auto mes optsmes'>
                            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                                <h2 className="messss fw-bold mb-2 text-uppercase">
                                    Reset Password
                                </h2>
                                <p className="text-white-50 mb-5">
                                    Your new password must at lest have one uppercase ,
                                    one lowercase ,
                                    one number and the length should be greater than 10 characters

                                </p>

                                <MDBInput wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='New Password'
                                          id='formControlLg' type='email'
                                          size="lg"
                                          onChange={passwordHandler}
                                />
                                <MDBInput wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Confirm New Password'
                                          id='formControlLg' type='email'
                                          size="lg"
                                          onChange={confirmPasswordHandler}
                                />
                                {error &&

                                    <p className="text-white-50 mb-4">{error} </p>
                                }

                                <MDBBtn outline className='dsali mx-2 px-5'
                                        color='white'
                                        size='lg'
                                        type="submit"
                                        onClick={handleSubmit}
                                        disabled={disableButton()}
                                >
                                    Reset Password
                                </MDBBtn>

                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </div>
    );
}

export default ResetPassword;
