import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navigate, useNavigate} from 'react-router-dom';
import MessageBox from "../messageBox/MessageBox";
import baseurl from "../../settings/urlsettings";

export function subsribeLetter(email) {
    var date = new Date();
    var req = {"email": email, "date": date.toString()}
    fetch(baseurl + "/person", {
    // fetch('https://www.morseverse.com/api/v1/person', {

        method: 'POST',
        body: JSON.stringify(req),
    })
}

function Popup() {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate()
    const handleclick = () => {
        window.location.replace('https://www.morseverse.com/environment');


    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function onClickSubmit(event) {
        event.preventDefault()
        const data = new FormData(event.target);
        if (data.get('email') === "") {
            MessageBox({
                type: "error",
                text: "Please fill the email box"
            });

        } else {
            subsribeLetter(data.get('email'));
            setEmail('')
            MessageBox({
                type: "success",
                text: "Subscribtion is completed"
            });
            navigate("/environment")
        }


    }

    return (
        <>
                  <img className='img-log' src={require('../../assets/images/playasgust copy1.png')}></img><button onClick={handleShow}  type="button" className='butt-log'><div className='changed-mes'><p className='p-buton-log1'>PLAY AS A GUEST</p>

</div></button>
            <Modal show={show} onHide={handleClose}>
                <img alt={"logo"} className='imgs' src={require('../../assets/images/logo.jpg')}/>
                <button onClick={handleClose} className='btn-closee'>
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path
                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
                <h2 className='headers'>Welcome</h2>
                <form onSubmit={onClickSubmit}>
                    <Modal.Body>Please suscribe to proceed</Modal.Body>
                    <input className='inputs' id="email" type="email" name="email" placeholder="Enter Your Email"
                           value={email} onChange={(e) => setEmail(e.target.value)}/>


                    <Modal.Footer>
                        <button className='buttonse' type='button' onClick={handleclick}>skip</button>

                        <button className="buttons" type="submit">Subscribe</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default Popup;