import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import baseurl from "../../settings/urlsettings.js";
import { lineChartOptions } from "./variable/charts.js";

const LineChart2 = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseurl}/people`);
      const data = await response.json();

      let filteredData = data;

      if (selectedMonth !== null) {
        filteredData = filteredData.filter(
          entry => new Date(entry.date).getMonth() === selectedMonth
        );
      }

      if (selectedYear !== null) {
        filteredData = filteredData.filter(
          entry => new Date(entry.date).getFullYear() === selectedYear
        );
      }

      const joinedCounts = {};
      let totalJoined = 0;
      filteredData.forEach(entry => {
        const date = new Date(entry.date);
        const day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (!joinedCounts[day]) {
          joinedCounts[day] = 0;
        }
        joinedCounts[day] += 1;
        totalJoined += 1;
      });

      const labels = Object.keys(joinedCounts);
      const xaxisValues = labels.map(label => new Date(label).getTime());

      const joinedData = Object.values(joinedCounts);

      const totalJoinedData = new Array(joinedData.length).fill(totalJoined);

      let cumulativeTotal = 0;
      const cumulativeTotalData = joinedData.map(count => {
        cumulativeTotal += count;
        return cumulativeTotal;
      });

      const mappedChartData = [
        {
          name: "total number",
          data: xaxisValues.map((value, index) => ({
            x: value,
            y: totalJoinedData[index],
          })),
        },
        {
          name: "total number till this day",
          data: xaxisValues.map((value, index) => ({
            x: value,
            y: cumulativeTotalData[index],
          })),
        },
        {
          name: "number of people joined this day",
          data: xaxisValues.map((value, index) => ({
            x: value,
            y: joinedCounts[labels[index]],
          })),
        },
      ];

      setChartData(mappedChartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [selectedMonth, selectedYear]);

  const monthNames = Array.from({ length: 12 }, (_, index) =>
    new Date(0, index).toLocaleDateString("en-US", { month: "long" })
  );

  lineChartOptions.xaxis.categories = monthNames;

  return (
    <div className="box-boxs">
      <div className="optionsss">
      <label className="select-lable-chart">
        <select className="select-chart"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
        >
          <option value={null}>All Months</option>
          {monthNames.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
        </label>
        <label className="select-lable-chart">
        <select className="select-chart"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
        >
          <option value={null}>All Years</option>
          {Array.from({ length: 10 }, (_, index) => (
            <option key={index} value={2023 - index}>
              {2023 - index}
            </option>
          ))}
        </select>
        </label>
        <div className="check-box-chart first-guide">
        <p className="guide-chart p-chart">Total number of people who joined</p>
        </div>
        <div className="check-box-chart second-guide">
        <p className="guide-chart">Total number of people who joined till today</p>
        </div>
        <div className="check-box-chart third-guide">
        <p className="guide-chart">Number of people who joined today</p>
        </div>
      </div>
      <ReactApexChart
        options={lineChartOptions}
        series={chartData}
        type="area"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default LineChart2;
