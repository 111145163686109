import "./community.css"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import "../carousle/card.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../carousle/card";
import {productData, responsive} from "./data.js";
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faDiscord} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Blogmorse} from '../morseblog/blogmorse.js';
import {Container, Row} from "react-bootstrap";
import React from "react";

export default function Community() {
    const card = productData.map((item) => (

        <Card
            key={item.id}
            name={item.name}
            imageurl={item.imageurl}
            url={item.url}
            price={item.price}
            description={item.description}
            link={item.link}
            linktext={item.linktext}
            urls={item.urls}
        />
    ));
    return (

        <div className="community-component">
            {/*<link*/}
            {/*    rel="stylesheet"*/}
            {/*    type="text/css"*/}
            {/*    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"*/}
            {/*/>*/}
            {/*<div className="container my-5">*/}
            {/*    <div className="row rowsss">*/}
            {/*        <div className="row rowssss">*/}
            {/*            <div className="ticker-wrapper-h">*/}
            {/*                <div className="heading margil">Trending Now</div>*/}
            {/*                <ul className="news-ticker-h">*/}
            {/*                    <li className="li_newsbar">Check out MorseVerse’s roadmap</li>*/}
            {/*                    <li className="li_newsbar">MorseVerse Partnered with Ready Player Me</li>*/}
            {/*                    <li className="li_newsbar">check out Morse Agent</li>*/}
            {/*                    <li className="li_newsbar">Explore NewYork at MorseVerse</li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="div-heights">*/}
            {/*                <a className="commu-social" href="https://twitter.com/theMorseVerse">*/}
            {/*                    <FontAwesomeIcon icon={faTwitter}/>*/}
            {/*                </a>*/}
            {/*                <a className="commu-social" href="https://www.linkedin.com/company/morseverse/">*/}
            {/*                    <FontAwesomeIcon icon={faLinkedin}/>*/}
            {/*                </a>*/}
            {/*                <a className="commu-social" href="https://www.instagram.com/themorseverse/">*/}
            {/*                    <FontAwesomeIcon icon={faInstagram}/>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*            <div className="row">*/}
            {/*                /!* Jumbotron *!/*/}
            {/*                <div className="col-lg-4 col-md-12">*/}
            {/*                    <div className="margi">*/}
            {/*                        <div className="ticker-wrapper-v-image">*/}
            {/*                            <ul className="news-ticker-v-image">*/}
            {/*                                <li>*/}
            {/*                                    <div className="thumbnail">*/}
            {/*                                        <a target="_blank">*/}
            {/*                                            /!* <img src="https://via.placeholder.com/150x80"> *!/*/}
            {/*                                            <div className="clear"/>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="news-info">*/}
            {/*                                        <div className="news_title">*/}
            {/*                                            <a*/}
            {/*                                                href="https://www.morseverse.com/environment"*/}
            {/*                                                target="_blank"*/}
            {/*                                            >*/}
            {/*                                                explore Search room*/}
            {/*                                            </a>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="news-content">*/}
            {/*                                            You can surf on the web while enjoying the metaverse tools*/}
            {/*                                            in our seach room. Try it now.*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="clear"/>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <div className="thumbnail">*/}
            {/*                                        <a target="_blank">*/}
            {/*                                            /!* <img src="https://via.placeholder.com/150x80"> *!/*/}
            {/*                                            <div className="clear"/>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="news-info">*/}
            {/*                                        <div className="news_title">*/}
            {/*                                            <a*/}
            {/*                                                href="/aimorse"*/}
            {/*                                                target="_blank"*/}
            {/*                                            >*/}
            {/*                                                Talk to AIMorse*/}
            {/*                                            </a>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="news-content">*/}
            {/*                                            AIMorse our primary AI agent is now ready to use, giving you*/}
            {/*                                            answers for your general questions. Check out our AI agent*/}
            {/*                                            now.*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="clear"/>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <div className="thumbnail">*/}
            {/*                                        <a target="_blank">*/}
            {/*                                            /!* <img src="https://via.placeholder.com/150x80"> *!/*/}
            {/*                                            <div className="clear"/>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="news-info">*/}
            {/*                                        <div className="news_title">*/}
            {/*                                            <a href="https://www.morseverse.com/environment"*/}
            {/*                                               target="_blank">*/}
            {/*                                                Explore NewYork at MorseVerse*/}
            {/*                                            </a>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="news-content">*/}
            {/*                                            First part of our real-world 3D map has been published. you*/}
            {/*                                            can explore it now.*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="clear"/>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <div className="thumbnail">*/}
            {/*                                        <a target="_blank">*/}
            {/*                                            /!* <img src="https://via.placeholder.com/150x80"> *!/*/}
            {/*                                            <div className="clear"/>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="news-info">*/}
            {/*                                        <div className="news_title">*/}
            {/*                                            <a*/}
            {/*                                                href="https://www.morseverse.com/environment"*/}
            {/*                                                target="_blank"*/}
            {/*                                            >*/}
            {/*                                                Gate of Heaven*/}
            {/*                                            </a>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="news-content">*/}
            {/*                                            Initial environment of the MorseVerse, Gate of Heaven, just*/}
            {/*                                            launched. create your avatar and jump into it.*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="clear"/>*/}
            {/*                                </li>*/}
            {/*                                <li>*/}
            {/*                                    <div className="thumbnail">*/}
            {/*                                        <a target="_blank">*/}
            {/*                                            /!* <img src="https://via.placeholder.com/150x80"> *!/*/}
            {/*                                            <div className="clear"/>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="news-info">*/}
            {/*                                        <div className="news_title">*/}
            {/*                                            <a*/}
            {/*                                                href="/PrivacyPolicy"*/}
            {/*                                                target="_blank"*/}
            {/*                                            >*/}
            {/*                                                privacy policy*/}
            {/*                                            </a>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="news-content">*/}
            {/*                                            Our Privacy Policy published you can now check it to see our*/}
            {/*                                            terms and find out how we handle our customer's or client's*/}
            {/*                                            information gathered in our operations.*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="clear"/>*/}
            {/*                                </li>*/}
            {/*                            </ul>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="col-lg-8 col-md-12">*/}
            {/*                    <div className="row">*/}

            {/*                        <Carousel showDots={false} responsive={responsive}>*/}
            {/*                            {card}*/}
            {/*                        </Carousel>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<Row className="row allsa">*/}
            {/*    <hr className="wis5"/>*/}
            {/*</Row>*/}

            {
                Blogmorse
            }
        </div>


    )
        ;
}

  
  
