import {Route} from "react-router-dom";
import Bookdemo from "../../components/bookDemo/bookdemo";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";

const BetaSignUpPage = () => {
    const [showDemo, SetShowDemo] = useState(true);
    return (
        <Container  fluid className="vh-100 d-flex justify-content-center align-items-center bg-light" >
            <Bookdemo show={showDemo} setShow={SetShowDemo}/>
        </Container>
    )

}
export default BetaSignUpPage