import "./loginpage.css"
import "./login-popup.css"
import "./script.js"
import Popup from "./popup.js"
import React, {useState} from "react"
import Login from "./login-popup.js"

//onClick={() => prompt('Coming soon subscribe down below to be the first to notified' , 'Email')}
export default function LoginPage({loginProps}) {
    const {
        wallet,
        connecting,
        connect,
        disconnect,
        ethersProvider,
        connected,
        recipient,
        amount,
        handleConnect,
        handleDisconnect,
    } = loginProps;

    /*const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }*/


    return (
      <div className="login-component">
               
    <div>
      <section className="bgsc">
        <div className="row h-100 d-flex justify-content-center align-items-center mmpop">
          <div className="col-lg-5 col-md-7">
            <form role="form">
              <div className="text-center">
              
                <Login loginProps={loginProps}></Login>
              </div>
            </form>
          </div>
          <div className="col-lg-5 col-md-7">
            <form role="form">
              <div className="text-center">
                
                <Popup></Popup>
                {/*isOpen && <Popup
                  handleClose={togglePopup}
                />*/}
                {/*- <a href="#" data-toggle="tooltip" data-placement="top" title="Hooray!">Top</a> */}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
      </div>
          )
      }
