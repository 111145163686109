const createDatas = [
    {src: require ('../../assets/images/ali_prof.jpg'),
    description:'hi my name is ali and im happy to be with youasdasds adadasdasd sadsadasdasdas dasdasdasd asdsadasdasdas dasdsadsadsa dasdasdasd asdasdasdasd dsadsadasd dsasdasd sadsadasdasd',
},
{src: require ('../../assets/images/ali_prof.jpg'),
description:'hi my name is ali and im happy to be with youasdasds adadasdasd sadsadasdasdas dasdasdasd asdsadasdasdas dasdsadsadsa dasdasdasd asdasdasdasd dsadsadasd dsasdasd sadsadasdasd',
},
{src: require ('../../assets/images/ali_prof.jpg'),
description:'hi my name is ali and im happy to be with youasdasds adadasdasd sadsadasdasdas dasdasdasd asdsadasdasdas dasdsadsadsa dasdasdasd asdasdasdasd dsadsadasd dsasdasd sadsadasdasd',
},
]
export default createDatas