import React, {useState, useEffect} from 'react';
import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes} from '@mui/x-data-grid';
import './adminpage.css';
import {Sidebarsdata} from './sidebarsdata';
import baseurl from "../../settings/urlsettings.js";
import {fetchSubscribelistData, updateSubscription} from "./api_for_adminpage";
import MessageBox from "../messageBox/MessageBox";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from "@mui/icons-material/Close";
import {TextBox} from './textbox.js';
import {Grid} from "@mui/material";
import {Row} from "react-bootstrap";


export default function Subscribedadmin() {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [rowSelectionModel, setRowSelectionModel] = useState([])

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        try {
            handleDelete(id).then(response => {
                if (!response.ok) {
                    MessageBox({
                        type: "error",
                        text: "bad request"
                    });
                    window.location.reload();
                }
                if (response.ok) {
                    setRows(rows.filter((row) => row.id !== id));
                    MessageBox({
                        type: "success",
                        text: "Data saved successfully"
                    });
                }
            });

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        try {
            handleSave(updatedRow).then(response => {
                    if (!response.ok) {
                        MessageBox({
                            type: "error",
                            text: "bad request"
                        });
                        window.location.reload();
                    }
                    if (response.ok) {
                        MessageBox({
                            type: "success",
                            text: "Data saved successfully"
                        });
                    }

                }
            );

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    useEffect(() => {
        fetchSubscribelistData().then((data) => setRows(data));

    }, []);

    const handleSave = async (params) => {
        console.log(params)
        return await updateSubscription(params);
    };

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            valueGetter: (params) => params.row.email || '',
            editable: true,
        },
        {
            type: 'dateTime',
            field: 'date',
            headerName: 'JoinData',
            flex: 1,
            valueGetter: (params) => params.row.date && new Date(params.row.date),
            // editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        },


    ];


    const handleDelete = async (id) => {

        console.log('Deleting user with ID:', id);
        return await fetch(`${baseurl}/person_remove`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "SubscriptionID": id,
            }),
        });

    }

    return (
        <div className='subscribedadmin'>
            {Sidebarsdata}

            <Grid style={{height: 1000, marginLeft:'25px',marginBottom: '25px', marginTop: '40px', float: 'right'}}>
                <Row>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 13},
                            },
                        }}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        pageSizeOptions={[13]}
                        checkboxSelection
                        rowKey={(row) => row.id}

                    />
                </Row>
                <Row style={{height:500}}>
                    <TextBox selectedRows={rowSelectionModel}  rows={rows}/>
                </Row>
            </Grid>

        </div>
    );
}
