import React, { useRef, useState} from 'react';
import 'react-quill/dist/quill.snow.css';

import './adminpage.css'

import Button from 'react-bootstrap/Button';
import baseurl from "../../settings/urlsettings";
import MessageBox from "../messageBox/MessageBox";
import EmailEditor from 'react-email-editor';
export const TextBox = (props) => {
    const [content, setContent] = useState('');
    const emailEditorRef = useRef(null);
        const sendEmails = () => {
            emailEditorRef.current.editor.exportHtml((data1) => {
                try {
                    const {design, html} = data1;
                    const data = props.rows.filter(
                        (row) => (props.selectedRows.includes(row.id)))
                        .map((row) => {
                            return row.email
                        });
                    if (data.length === 0) {
                        MessageBox({
                            text: "please select the users",
                            type: "error"
                        });
                        return
                    }
                    if (html.length === 0) {
                        MessageBox({
                            text: "please enter the email content",
                            type: "error"
                        });
                        return
                    }



                    fetch(baseurl + "/subscriptionEmail", {

                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        credentials: "include",
                        body: JSON.stringify({
                            "email": data,
                            "about": html,

                        })
                    }).then(async response => {
                        if (response.ok) {
                            MessageBox({
                                text: "Email sent successfully",
                                type: "success"
                            });

                        } else {
                            MessageBox({
                                text: "please try later ",
                                type: "error"
                            });

                        }
                    })
                } catch (error) {
                    MessageBox({
                        text: error,
                        type: "error"
                    });
                }
            });


        }

        const onReady = () => {

        };

        return (
            <div  className='text-areas'>

                <EmailEditor ref={emailEditorRef} onReady={onReady}/>
                <div>
                    <Button variant="primary"  onClick={sendEmails}>Send Email</Button>
                </div>
            </div>
        );
    };

