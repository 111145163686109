import "./bookdemo.css"
import {Container, Form, Button, Row, Col} from 'react-bootstrap';
import {useState} from "react";
import baseurl from "../../settings/urlsettings";
import MessageBox from "../messageBox/MessageBox";
import {navigate} from "use-history";
import Modal from "react-bootstrap/Modal";
import {useNavigate} from "react-router-dom";


const DemoForm = ({show, setShow, page}) => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    // const [country, setCountry] = useState('');
    // ;
    // const [communityScale, setCommunityScale] = useState('');
    // const [message, setMessage] = useState('');
    const [goals, setGoals] = useState('');

    const handleClose = () =>
        {
            setShow(false)
            navigate("/homepage");

        }
    ;
    const handleShow = () => setShow(true);
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Consolidate form data
        const data = {
            "firstName": firstName,
            // "lastName": lastName,
            "email": email,
            "website": website,
            // "country": country,
            // "communityScale": communityScale,
            // "message": message,
            "goals": goals
        };
        fetch(baseurl + "/demo", {

            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(data)
        }).then(async response => {
            if (response.ok) {
                MessageBox({
                    text: "we will contact you soon",
                    type: "success"
                });
                navigate("/");
                setShow(false);

                // window.location.reload();
            } else {
                MessageBox({
                    text: "please try later ",
                    type: "error"
                });

            }
        })
            .catch(error => {
                console.error('Error during fetch operation:', error);
                MessageBox({
                    text: "Network or server issue, please try again later.",
                    type: "error"
                });
            });
    }

    return (
        <>
            <Modal scrollable={true} show={show} onHide={handleClose} fullscreen={'md-down'}
                   dialogClassName="custom-modal-style">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h1 className="modal-main-title">Sign up for Beta</h1>
                        <h2 className="modal-sub-title">Please fill out the form to join the waiting list for the beta
                            version and be among the first to use MorseVerse's virtual shops.</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="firstname">
                                        <Form.Label>Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" required value={firstName}
                                                      onChange={(e) => setFirstName(e.target.value)}/>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email<span className="required">*</span></Form.Label>
                                <Form.Control type="email" required value={email}
                                              onChange={(e) => setEmail(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="website">
                                <Form.Label>Website URL<span className="required">*</span></Form.Label>
                                <Form.Control type="url" required value={website}
                                              onChange={(e) => setWebsite(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="goals">
                                <Form.Label>Please let us know what your business does and how you plan to use virtual
                                    shops.</Form.Label>
                                <Form.Control as="textarea" rows={2} value={goals}
                                              onChange={(e) => setGoals(e.target.value)}/>
                            </Form.Group>
                            <Button variant="primary" className={"btn-book-demo"} type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DemoForm;