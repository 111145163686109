import baseurl from "../../settings/urlsettings";
import axios from "axios";

export const handleApiResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch data');
    }
    return response.json();
};
export const fetchAiSetting = async (companyId) => {

    try {
        const response = await fetch(`${baseurl}/aiSettings/${companyId}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching AI settings:', error);
        console.log("hi")
        throw error;
    }
};
export const updateAiSetting = async (companyId, data) => {
    try {
        const response = await fetch(`${baseurl}/aiSettings/${companyId}`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(data)
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error updating AI agent:', error);
        throw error;
    }
};
// Fetch AI list
export const fetchgetAiList = async (companyId) => {
    try {
        const response = await fetch(`${baseurl}/getAiList/${companyId}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching AI list:', error);
        throw error;
    }
};

// Fetch general information
export const fetchInformation = async (companyId) => {
    try {
        const response = await fetch(`${baseurl}/ai_info/${companyId}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching information:', error);
        throw error;
    }
};

export const updateInformation = async (companyId, data) => {
    try {
        const response = await fetch(`${baseurl}/ai_info/${companyId}`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(data)
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error updating information:', error);
        throw error;
    }
};
// Fetch appearance settings
export const fetchAppearance = async (companyId) => {
    try {
        const response = await fetch(`${baseurl}/appearance/${companyId}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching appearance data:', error);
        throw error;
    }
};
export const updateAppearance = async (companyId, appearanceData) => {
    try {
        const response = await fetch(`${baseurl}/appearance/${companyId}`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(appearanceData)
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error updating appearance:', error);
        throw error;
    }
};
// Fetch conversation summaries
export const fetchConversations = async (companyId) => {
    try {
        const response = await fetch(`${baseurl}/ai_summary/${companyId}`, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
            credentials: "include"
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error fetching conversations:', error);
        throw error;
    }
};

export const submitBugReport = async (companyId, reportData) => {
    try {
        const response = await fetch(`${baseurl}/bugreport`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(reportData)
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error submitting bug report:', error);
        throw error;
    }
};

export const change_report = async (companyId, reportData) => {
    try {
        const response = await fetch(`${baseurl}/ai_agent`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            credentials: "include",
            body: JSON.stringify(reportData)
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error submitting bug report:', error);
        throw error;
    }
};
export const uploadFile = async (selectedFile) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
        const response = await fetch(`${baseurl}/fileUpload`, {
            method: 'POST',
            body: formData,
        });
        return await handleApiResponse(response);
    } catch (error) {
        console.error('Error Uploading a file :', error);
        throw error;
    }
};


export const handleDeleteFile = async (fileId) => {

    try {
        const response = await fetch(`${baseurl}/deleteFile/${fileId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        return await handleApiResponse(response);

    } catch (error) {
        console.error('Error Deleting file:', error);
        throw error;
    }

};