import {Col, Form, Row} from "react-bootstrap";
import React, {useState} from "react";

const BugReport = (
    {
        description,
        setDescription,
        steps,
        setSteps,
        contact,
        setContact,
        setHasChange

    }
) => {


    // Handlers for updating state
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setHasChange(true);
    };
    const handleStepsChange = (e) => {
        setSteps(e.target.value);
        setHasChange(true);
    };
    const handleContactChange = (e) =>{
        setContact(e.target.checked)
        setHasChange(true);
    };
    return (

        <Form className={"bugReport"}>
            <Form.Group>
                <Row>
                    <Col>
                        <Form.Label>Brief description of the issue</Form.Label>
                    </Col>
                </Row>

                <Row>
                    <Col sm={1}/>
                    <Col>
                        <Form.Control
                            className="bugReport"
                            as="textarea"
                            rows={2}
                            placeholder={""}
                            value={description} // Set the value to state
                            onChange={handleDescriptionChange} // Update state on change
                        />
                    </Col>
                    <Col sm={1}/>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col>
                        <Form.Label>Steps to reproduce the issue</Form.Label>
                    </Col>
                </Row>

                <Row>
                    <Col sm={1}/>
                    <Col>
                        <Form.Control
                            className="bugReport"
                            as="textarea"
                            rows={5}
                            placeholder={""}
                            value={steps} // Set the value to state
                            onChange={handleStepsChange} // Update state on change
                        />
                    </Col>
                    <Col sm={1}/>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row className={"bugReport_Check_point"}>
                    <Col sm={2}/>
                    <Col>
                        <Form.Check
                            label={"Can we contact you when the issue is resolved?"}
                            checked={contact} // Set checked state
                            onChange={handleContactChange} // Update state on change
                        />
                    </Col>
                    <Col sm={2}/>
                </Row>
            </Form.Group>
        </Form>


    )
};
export default BugReport;