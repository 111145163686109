import React, { useState } from "react";
import "./searchbar.css";

function Searchbaar({ data }) {
  const [filteredData, setFilteredData] = useState([]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = data.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  // Filter duplicated items based on the 'preview' field
  const filteredDataUnique = Array.from(new Set(filteredData.map((item) => item.preview))).map((preview) => {
    return filteredData.find((item) => item.preview === preview);
  });

  return (
    <div className="search">
      <div className="search-boxs">
      <svg className="marginsvgme" fill="#000000" width="18px" height="18px" viewBox="0 -0.24 28.423 28.423" id="_02_-_Search_Button" data-name="02 - Search Button" xmlns="http://www.w3.org/2000/svg">
  <path id="Path_215" data-name="Path 215" d="M14.953,2.547A12.643,12.643,0,1,0,27.6,15.19,12.649,12.649,0,0,0,14.953,2.547Zm0,2A10.643,10.643,0,1,1,4.31,15.19,10.648,10.648,0,0,1,14.953,4.547Z" transform="translate(-2.31 -2.547)" fill-rule="evenodd"/>
  <path id="Path_216" data-name="Path 216" d="M30.441,28.789l-6.276-6.276a1,1,0,1,0-1.414,1.414L29.027,30.2a1,1,0,1,0,1.414-1.414Z" transform="translate(-2.31 -2.547)" fill-rule="evenodd"/>
</svg>
        <input
          className="search-styles input-search me-2 form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
          onChange={handleFilter}
        />
      </div>
      {filteredDataUnique.length !== 0 && (
        <div className="dataResult">
          {filteredDataUnique.slice(0, 10).map((value, key) => {
            return (
              <a className="dataItem" href={value.link} target="_blank" key={key}>
                <p>{value.preview} </p>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Searchbaar;
