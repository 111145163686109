import React, {useState, useEffect} from 'react';
import './adminpage.css';
import {Sidebarsdata} from './sidebarsdata';
import baseurl from "../../settings/urlsettings.js";
import {fetchUserlistData} from './api_for_adminpage';
import MessageBox from "../messageBox/MessageBox";
import LineChart from "./userchart.js";
import LineChart2 from "./subscribechart.js";
import {
    lineChartData,
    lineChartOptions
} from "./variable/charts.js";
import axios from "axios";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Table from 'react-bootstrap/Table';
import * as PropTypes from "prop-types";
import {Card} from "react-bootstrap";

// const fetchFacebook = async (username) => {
//
//     axios.get(`https://graph.facebook.com/${username}?fields=followers_count,friends_count&access_token=${FB_TOKEN}`)
//         .then(response => {
//             console.log(response.data)
//         })
//         .catch(error => console.log(error));
// };
// const fetchInstagram = async (username) => {
//
//     axios.get(`https://graph.instagram.com/${username}?fields=followers_count,follows_count&access_token=${IG_TOKEN}`)
//         .then(response => {
//             console.log(response.data)
//         })
//         .catch(error => console.log(error));
// };
// const fetchLinkdin = async (username) => {
//
//     axios.get(`https://api.linkedin.com/v2/me?oauth2_access_token=${LINKIN_TOKEN}`)
//         .then(response => {
//             console.log(response.data)
//         })
//         .catch(error => console.log(error));
// };
// const fetchdiscord = async (username) => {
//
//     axios.get(`https://api.linkedin.com/v2/me?oauth2_access_token=${LINKIN_TOKEN}`)
//         .then(response => {
//             console.log(response.data)
//         })
//         .catch(error => console.log(error));
// };


const SocialMediaInfo = () => {
    const [socialMediaList, setSocialMediaList] = useState([
        {
            name: "Twitter",
            icon: TwitterIcon,
            followers: 0,
            following: 0,
            // url: "https://twitter.com/theMorseVerse"
        },
        // {
        //     name: "FaceBook",
        //     // icon: FacebookIcon,
        //     followers: 0,
        //     following: 0,
        //     // get: fetchFacebook("themorseverse")
        // },
        // {
        //     name: "Instagram",
        //     // Icon: InstagramIcon,
        //     followers: 0,
        //     following: 0,
        //     // get: fetchInstagram("themorseverse")
        //
        // },
        // {
        //     name: "linkedin",
        //     // Icon: LinkedInIcon,
        //     followers: 3,
        //     following: 0,
        //     // get: fetchLinkdin("morseverse")
        // },
        // {
        //     name: "Discord",
        //     // Icon: require("../../assets/dashboard/discord.png"),
        //     followers: 2,
        //     following: 0,
        //     // get: fetchdiscord("MorseVerse")
        // }
    ])
    const [twfolower, setTwfolower] = useState(0)
    const [twfolowwing, setTwfolowwing] = useState(0)
    useEffect(() => {

        const fetchTwitterData = async () => {
            try {
                const response = await fetch(`${baseurl}/twitterinfo`);
                if (!response.ok) {
                    MessageBox({
                        type: "error",
                        text: "bad request"
                    });
                }

                const twitterInfo = await response.json();
                let newsocialMediaList = socialMediaList
                newsocialMediaList[0].followers = twitterInfo["followers_count"]
                newsocialMediaList[0].following = twitterInfo["friends_count"]
                setSocialMediaList(newsocialMediaList)

            } catch (error) {
                console.error('Error fetching user list data:', error);
            }


        };
        fetchTwitterData();


    }, [socialMediaList])


    return (
        <>
            <h1 className='h1-topic-me h1s-topic-me'>SocialMedia</h1>

            {

                socialMediaList.map(
                    (data) => (
                        <Card key={socialMediaList}>
                            <Card.Header>
                                {
                                    data.name
                                }
                            </Card.Header>

                            <Card.Body>
                                <ul>
                                    <li> <text> Mouse Verse folowings :  </text>
                                        {
                                            data.following
                                        }
                                    </li>
                                    <li>
                                        <text> Mouse Verse followers :  </text>
                                        {
                                            data.followers
                                        }
                                    </li>
                                </ul>

                            </Card.Body>


                        </Card>
                    )
                )
            }


        </>
    )

}

export default function Admindashboard() {


    return (

        <div className='admindashboard'>
            {Sidebarsdata}
            <div className='organizzme'>

                <SocialMediaInfo/>

                <h1 className='h1-topic-me h1s-topic-me'>User Chart</h1>
                <div className='box-charts'>
                    <LineChart
                        chartOptions={lineChartOptions}
                    />
                </div>
                <h1 className='h1s-topic-me'>Subscribe Chart</h1>
                <div className='box-charts2'>
                    <LineChart2
                        chartOptions={lineChartOptions}
                    />
                </div>
            </div>
        </div>


    )
        ;
}
