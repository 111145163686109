import React from 'react';
import "./topicheader.css"
import Searchbaar from './searchbar.js';

import {blogData} from "./blogData";

export const Topicheader = [
    <div className='topicheader'>
                       <div className="header-topic">
            
            <div className="header-content">
            <div className="container">
                <div className="left-contents">
                    <a className="left-nav" href='/blog'>Blog</a>
                    <div className="active-link-blog"></div>
                </div>
                <div className="right-contents">
                    
                <Searchbaar data={blogData}/>
                </div>
            </div>
            </div>
        </div> 
        </div>
];