import React, {useContext, useState} from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,

}
    from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './signin.css'
import MessageBox from "../messageBox/MessageBox";
import {useNavigate} from "react-router-dom";
import baseurl from "../../settings/urlsettings";


function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const submit = async (e) => {
        e.preventDefault();

        // await fetch('https://www.morseverse.com/api/v1/login', {
        await fetch(baseurl + '/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: "include",
            body: JSON.stringify({
                email,
                password
            })
        }).then(async response => {
            if (response.ok) {
                navigate("/developerDashboard")
                window.location.reload();

            } else if (response.status === 400) {
                MessageBox({
                    type: "error",
                    text: "Your Email or your password in incorrect"
                });

            } else if (response.status === 404) {
                MessageBox({
                    type: "error",
                    text: "Your Email or your password in incorrect"
                });


            } else {
                MessageBox({
                    type: "error",
                    text: "An error occurred"
                });
            }
        })
            .catch(error => {
                MessageBox({
                    type: "error",
                    text: "A network error occurred"
                });

            })

    }

    return (
        <div className='body_signin'>


            <MDBContainer fluid>

                <MDBRow className='messs d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>

                        <MDBCard className='bg-dark text-white my-5 mx-auto mes optsmes'
                        >
                            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                                <h2 className="messss fw-bold mb-2 text-uppercase">Login</h2>
                                <p className="text-white-50 mb-5">
                                    Please enter your email and password!
                                </p>

                                <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Email address'
                                          id='formControlLg' type='email'
                                          size="lg"
                                          value={email}
                                          onChange={e => setEmail(e.target.value)}
                                />
                                <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Password'
                                          id='formControlLg'
                                          type='password'
                                          size="lg"
                                          value={password}
                                          onChange={e => setPassword(e.target.value)}
                                />

                                <p className="small mb-3 pb-lg-2">
                                    <a class="text-white-50" href="/forgetpassword">
                                        Forgot password?
                                    </a></p>
                                <MDBBtn outline className='dsali mx-2 px-5'
                                        color='white'
                                        size='lg'
                                        type="submit"
                                        onClick={submit}
                                >
                                    Login
                                </MDBBtn>


                                <div>
                                    <p className="jesss mb-0">
                                        Don't have an account?
                                        <a href="/Singup"
                                           class="text-white-50 fw-bold">
                                            Sign Up
                                        </a>
                                    </p>

                                </div>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </div>
    );

}

export default Login;

