import React, {useState} from 'react';
import "./authpop.css"
import {
    MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBCheckbox
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../register.css'
import {Link, Navigate} from "react-router-dom";
import {subsribeLetter} from "../../loginpage/popup";
import MessageBox from "../../messageBox/MessageBox";
import baseurl from "../../../settings/urlsettings";
import Modal from "react-bootstrap/Modal";

import {CloseButton} from "react-bootstrap";



export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export function isValidPassword(password) {
    const lowerCase = /[a-z]/g;
    const upperCase = /[A-Z]/g;
    const numbers = /[0-9]/g;
    if (!password.match(lowerCase)) {
        return "Password should contains lowercase letters!";
    } else if (!password.match(upperCase)) {
        return "Password should contain uppercase letters!";
    } else if (!password.match(numbers)) {
        return "Password should contains numbers also!";
    } else if (password.length < 8) {
        return "Password length should be more than 8.";
    } else {
        return null;
    }
}

function StandaredSignUpPopUp(props) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [policy, setPolicy] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [error, setError] = useState(null);
    const handleEmailChange = event => {
        if (!isValidEmail(event.target.value)) {
            setError('Email is invalid');
        } else {
            setError(null);
        }
        setEmail(event.target.value);
    };
    const handlePasswordChange = event => {
        setError(isValidPassword(event.target.value))
        setPassword(event.target.value);

    };

    const submit = async event => {
        event.preventDefault();
        setError(isValidPassword(password))
        if (error != null)
            return;
        if (newsletter) {
            subsribeLetter({"email": email});
        }
        console.log(baseurl)
        await fetch(baseurl + '/register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "name": username.toLowerCase(),
                email,
                password,
                "date": new Date().toString()
            })
        }).then(async response => {
            if (response.ok) {
                MessageBox({
                    type: "success",
                    text: "your signup is completed"
                });

                props.login()

            } else if (response.status === 400) {
                MessageBox({
                    type: "error",
                    text: "Invalid request"
                });

            } else if (response.status === 409) {
                MessageBox({
                    type: "error",
                    text: await response.text()
                });


            } else {
                MessageBox({
                    type: "error",
                    text: "An error occurred"
                });
            }
        })
            .catch(error => {
                MessageBox({
                    type: "error",
                    text: "A network error occurred"
                });

            })

    }


    const disableButton = () => {

        if (error ||
            username.trim() === "" ||
            password.trim() === "" ||
            email.trim() === "" ||
            !policy
        )
            return true;
        else
            return false;
    }

    return (

        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={props.hide}
            data-backdrop="static"
            contentClassName={"containerlogin bg-dark"}
        >
            <div>
                {
                    !props.returnPath &&
                <CloseButton className={'ModelCloseButton'} variant={"white"} onClick={ props.hide}/>
                }
            </div>

            <Modal.Body className={'flex-center p-0 '}>
                <MDBCard id={"login_card"} className='bg-dark text-white '>
                    <MDBCardBody className=' d-flex flex-column align-items-center  '>

                        <h2 className="messss fw-bold mb-2 text-uppercase">Register</h2>
                        <p className="text-white-50 mb-3">Please enter your information!</p>
                        <MDBInput className='change-input-sign' wrapperClass='mb-3 mx-5 w-100'
                                  labelClass='text-white'
                                  label='Username'
                                  id='form1' type='text' size="lg"
                                  onChange={e => setUsername(e.target.value)}
                        />
                        <MDBInput className='change-input-sign' wrapperClass='mb-3 mx-5 w-100'
                                  labelClass='text-white'
                                  label='Email address'
                                  id='formControlLg' type='email' size="lg"
                                  onChange={handleEmailChange}


                        />
                        <MDBInput className='change-input-sign' wrapperClass='mb-3 mx-5 w-100'
                                  labelClass='text-white'
                                  label='Password'
                                  id='formControlLg'
                                  type='password'
                                  size="lg"
                                  onChange={handlePasswordChange}

                        />
                        {error &&

                            <p className="text-white-50 mb-3">{error} </p>
                        }

                        <MDBRow className='mb-3  mx-5 w-100 '>
                            <MDBCol className='d-flex' id="checkboxRegform">
                                <MDBCheckbox name='flexCheck'
                                             onChange={e => setNewsletter(e.target.checked)}
                                             id='flexCheckDefault'/>
                                <text>
                                    Subscribe to our newsletter
                                </text>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mb-3 mx-5 w-100 '>
                            <MDBCol className='d-flex' id="checkboxRegform">
                                <MDBCheckbox name='flexCheck'
                                             onChange={e => setPolicy(e.target.checked)}
                                             id='flexCheckDefault'/>
                                <Link className='mb-3   ' to="/Privacypolicy">
                                    Agree the terms and condition
                                </Link>
                            </MDBCol>
                        </MDBRow>

                        <MDBBtn outline className='dsalis mx-3 px-5' color='white' size='lg'
                                type="submit"
                                onClick={submit} disabled={disableButton()}>
                            Sign Up
                        </MDBBtn>


                        <div>
                            <p className=" mb-1">Do you have an account?
                            </p>

                             <MDBBtn outline color='white' className="text-white-50 fw-bold mb-1" onClick={props.login}>
                                login
                             </MDBBtn>

                        </div>
                    </MDBCardBody>

                </MDBCard>


            </Modal.Body>
        </Modal>
    );
}

export default StandaredSignUpPopUp;
