import React from "react";
import { Link, useNavigate } from "react-router-dom";


export default function Card(props) {

    const navigate = useNavigate();
    return (
        <div className="card cardss" style={{width:"auto", display:"list-item"}}>
            <a href={props.url}>
                <img className="product--image imgopt" src={props.imageurl} alt="product image"/>
                </a>


            <h2 style={{minHeight:"120px" ,display:"grid",alignItems:"center" }}>{props.name}</h2>
            {props?.hight &&
                <div
                    className="customize-me"
                    style={{
                        width: "100%",
                        maxHeight: props.hight,
                        overflowY: "scroll",
                        fontFamily: "Open Sans, sans-serif"
                    }}
                >{props.description}
                  {
                    props.link!==null &&
                    <Link to={props.link}>
                      &nbsp; {props.linktext}
                    </Link>
                  }
                </div>
            }
            {
                !props?.hight &&
                <div
                    className="customize-me"
                    style={{
                        width: "100%",
                        maxHeight: 60,
                        minHeight: 60,
                        overflowY: "scroll",
                        fontFamily: "Open Sans, sans-serif"
                    }}
                >{props.description}
                  {
                    props.link!==null &&
                    <Link to={props.link}>
                      &nbsp; {props.linktext}
                    </Link>
                  }
                </div>

            }


            {/* <p>{props.description}</p> */}
            {!props.hide &&

                <div className="row">
                    <p>
                        <a href={props.urls}>
                        <button className="btn btn-primary btnopt"
                                style={{display: props.display}}>More
                        </button>
                        </a>
                    </p>
                </div>

            }
        </div>
    )
        ;
}