import { toast} from 'react-toastify';
import React from "react";
const  MessageBox = (props) => {

            if (props.type==="error"){
            toast.error(props.text, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000
            })
            }
            else if(props.type==="success" ){
                toast.success(props.text, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000
                })
            }
        };





export default MessageBox;