import "./App.css";
import logo from "./logo.svg";
import Navbar_ex from "./components/navbar/navbar";
import Footer_ex from "./components/footer/footer";

import "font-awesome/css/font-awesome.min.css";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import React, {useEffect, useState} from "react";
import baseurl, {CorrectBaseUrl} from "./settings/urlsettings";
import {init, useConnectWallet} from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import icon from './assets/images/official logo transparent1.png';
import coinbase from '@web3-onboard/coinbase';
import gnosis from '@web3-onboard/gnosis';
import {ethers} from 'ethers';
import axios from 'axios';
import walletConnect from '@web3-onboard/walletconnect';
import AppRoutes from "./routes";
import {Container, Row} from "react-bootstrap";
import Bookdemo from "./components/bookDemo/bookdemo";
import CookieModal from "./components/Cookie/CookieModal";

const flatted = require('flatted');
const apiKey = '9a4407af-2c66-43af-8e65-7cf887820277';
const injected = injectedModule();
const rpcUrl = `https://mainnet.infura.io/v3/e93474c020df4144b76323b225895862`;
const coinbaseModule = coinbase();
const gnosisModule = gnosis();
const walletConnectModule = walletConnect();

init({
    apiKey,
    wallets: [injected, coinbaseModule, gnosisModule, walletConnectModule],
    chains: [
        {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl
        }
    ],
    appMetadata: {
        name: "Morseverse",
        icon: icon,
        logo: logo,
        description: "Morseverse"
    },
    accountCenter: {
        desktop: {
            enabled: true,
            position: 'bottomRight',

        },
        mobile: {
            enabled: true,
            position: 'bottomRight'
        }
    }
});

export async function GetUserInfo() {
    const response = await fetch(baseurl + "/user", {
        method: "Get",
        headers: {"Content-Type": "application/json"},
        credentials: "include"
    });

    return await response.json();
}

function setDataWithExpiry(key, data, ttl) {
    const now = new Date();
    const item = {
        value: data,
        expiry: now.getTime() + ttl,
    }
    window.localStorage.setItem(key, JSON.stringify(item));
}

function getDataWithExpiry(key) {
    const itemStr = window.localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage and return null
        window.localStorage.removeItem(key);
        return null;
    }
    return item.value;
}


function App() {
    const correctBaseUrl = CorrectBaseUrl;
    const [user, setUser] = useState("");
    const [login, setLogin] = useState(false);
    const [facebookLogin, setFacebookLogin] = useState(false);
    const [googleLogin, setGoogleLogin] = useState(false);
    const [showdemo, setShowdemo] = useState(false);
    const location = useLocation();

    const [{wallet, connecting}, connect, disconnect, , , setWallet] = useConnectWallet();
    const [ethersProvider, setEthersProvider] = useState(null);
    const [connected, setConnected] = useState(false);
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');
    useEffect(() => {
        if (window.location.href.indexOf(correctBaseUrl) === -1) {
            window.location.href = correctBaseUrl + window.location.pathname + window.location.search;
        }
        const handleEthereum = () => {
            let reloadCount = parseInt(getDataWithExpiry('reloadCount') || '0');

            const value = getDataWithExpiry('value');
            if (value) {
                axios.get(baseurl + `/getwallet/${value}`).then(response => {
                    console.log(response)
                    const savedWallet = flatted.parse(response.data.walletData);

                    setWallet(savedWallet, savedWallet.accounts[0].address);
                })
            } else if (!getDataWithExpiry('firstLogin') && window.ethereum && window.ethereum.selectedAddress) {
                setDataWithExpiry('firstLogin', 'true', 3600000); //1 hour TTL
                connect();
            }

            setDataWithExpiry('reloadCount', (reloadCount + 1).toString(), 3600000); //1 hour TTL
        };

        window.addEventListener('ethereum#initialized', handleEthereum, {once: true});
        setTimeout(handleEthereum, 3000);
    }, [connect, setWallet]);

    useEffect(() => {
        if (wallet) {
            if (!getDataWithExpiry('firstLogin')) {
                const provider = new ethers.providers.Web3Provider(wallet.provider, 'any');
                console.log(provider)
                setEthersProvider(provider);
                setDataWithExpiry('firstLogin', 'true', 3600000); //1 hour TTL
            }
            setConnected(true);

            console.log(flatted.stringify(wallet))
            axios.post(baseurl + '/createWallet', {"walletData": flatted.stringify(wallet)}).then(response => {
                console.log(response.data.InsertedID)
                setDataWithExpiry('value', response.data.InsertedID, 3600000); //1 hour TTL
            })
        } else {
            setEthersProvider(null);
            setConnected(false);
        }
    }, [wallet]);

    const handleConnect = async () => connect();

    const handleDisconnect = async () => {
        window.localStorage.clear();

        window.localStorage.removeItem('wallet');
        window.localStorage.removeItem('reloadCount');
        window.localStorage.removeItem('firstLogin');
        await disconnect(wallet);

        setConnected(false);
        setEthersProvider(null)
        console.log(wallet)
    };

    const loginProps = {
        wallet,
        connecting,
        connect,
        disconnect,
        ethersProvider,
        connected,
        recipient,
        amount,
        handleConnect,
        handleDisconnect,
    };

    useEffect(() => {

            const Checkuser = async () => {
                try {
                    const content = await GetUserInfo();
                    // console.log(content);
                    setUser(content);
                    setLogin(true);

                } catch (e) {
                    console.log(e);
                }

            }
            Checkuser();
        }
        , [correctBaseUrl, location.pathname]);

    useEffect(() => {
        if (user !== "")
            setLogin(true);
        else
            setLogin(false);
    }, [user]);
    return (
        <div className="App">
            <Navbar_ex
                user={user?.name}
                setUser={setUser}
                facebook={[facebookLogin, setFacebookLogin]}
                google={[googleLogin, setGoogleLogin]}
                key={"nav" + user}
                setShowdemo ={setShowdemo}
            />
            <Container className={"app_container"}>
                <Row>
                    <Outlet/>
                    <CookieModal />
                    <Bookdemo show={showdemo} setShow={setShowdemo}/>
                    <div className="main_content">
                        <AppRoutes key={user + "generaldash"} login={login} connected={connected} user={user}
                                   loginProps={loginProps} setUser={setUser}
                                   facebook={[facebookLogin, setFacebookLogin]}
                                   google={[googleLogin, setGoogleLogin]}
                                   showdemo ={showdemo}
                                   setShowdemo ={setShowdemo}

                        />
                    </div>
                </Row>

            </Container>
            <Footer_ex setShowdemo={setShowdemo}/>
            <ToastContainer/>

        </div>


    );


}

export default App;