import React, {useState, useEffect} from 'react';
import "./verification.css"
import {MDBCard, MDBCardBody, MDBCol, MDBContainer} from "mdb-react-ui-kit";
import baseurl from "../../settings/urlsettings";

const AccountVerification = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const verifyAccount = async (token) => {
        try {
            const response = await fetch(baseurl+'/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token}),
            });

            if (response.ok) {
                setIsVerified(true);
            } else {
                setIsVerified(false);
            }
        } catch (error) {
            console.error('Error verifying account:', error);
            setIsVerified(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            verifyAccount(token);
        } else {
            setIsLoading(false);
        }
    }, []);

    return (
        <div className="bodyVerification">
            <MDBContainer fluid>
                <MDBCol col='12' className=" py-5" id="forgetCard">

                    <MDBCard className='bg-dark text-white my-5 mx-auto mes optsmes'>
                        <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                            <div>
                                {isLoading ? (
                                    <p>Loading...</p>
                                ) : isVerified ? (
                                    <p>Account successfully verified! You can now log in.</p>
                                ) : (
                                    <p>Account verification failed. Please check the link or contact support.</p>
                                )}
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

            </MDBContainer>
        </div>
    );
};

export default AccountVerification;
