import React, {useState} from 'react';
import {Form, Button, Col, Row, Container, Dropdown} from 'react-bootstrap';
import "./developerDashbord.css"
import {UploadBtn} from "../../components/special-ai/special-ai-uploadFile";
import {handleDeleteFile, submitBugReport, uploadFile} from "./api_calls";
import MessageBox from "../../components/messageBox/MessageBox";

export const TextareaInputField = ({label, value, setValue, setHasChanged, placeholder}) => (
    <Form.Group className="developer_form_margin" as={Col} md={5}>
        <Row>
            <Form.Label>{label}</Form.Label>
        </Row>
        <Row>
            <Form.Control
                className="ai_link"
                as="textarea"
                placeholder={placeholder}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    setHasChanged(true);
                }}
            />
        </Row>
    </Form.Group>
);


const DropdownLanguage = ({language, setHasChanged, setLanguage}) => (
    <Form.Group className="developer_form_margin" as={Col} sm={5}>
        <Row>
            <Form.Label>Which languages do you want your agent to provide services in?</Form.Label>
        </Row>
        <Row>
            <Col sm={10}>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {language}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            setLanguage("English")
                            setHasChanged(true);
                        }}>English</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            setLanguage("Italian")
                            setHasChanged(true);
                        }}>Italian</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    </Form.Group>);

const LinkInputFields = ({links, setLinks, setHasChanged, label}) => {
    const handleAddLink = () => {
        setLinks([...links, ""]);
    };

    const handleChange = (index, e) => {
        const newLinks = [...links];
        newLinks[index] = e.target.value;
        setLinks(newLinks);
        setHasChanged(true);
    };

    const handleDeleteLink = (index) => {
        const newLinks = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
        setHasChanged(true);
    };

    return (
        <Form.Group className="developer_form_margin">
            <Row>
                <Col md={9}>
                    <Form.Label>{label}</Form.Label>
                </Col>
                <Col md={3}>
                    <Button variant="success" size="sm" onClick={handleAddLink} className="p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                             className="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>

                    </Button>
                </Col>
            </Row>
            {links.map((link, index) => (
                <Row key={index} className="align-items-center links developer_form_margin">
                    <Col md={8}>
                        <Form.Control
                            className="ai_link"
                            type="text"
                            placeholder="Enter your link"
                            value={link}
                            onChange={e => handleChange(index, e)}
                        />
                    </Col>
                    <Col md={1}>
                        <Button variant="danger" size="sm" onClick={() => handleDeleteLink(index)} className="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                 className="bi bi-trash3-fill" viewBox="1 -1 13 20">
                                <path
                                    d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                            </svg>
                        </Button>
                    </Col>
                </Row>
            ))}
        </Form.Group>
    );
};

const Linkdocument = ({links, setLinks, document, setDocument, label, setHasChanged}) => {


    const handleChange = (index, e) => {
        const newLinks = [...links];
        newLinks[index] = e.target.value;
        setLinks(newLinks);
    };

    const handleDeleteLink = async (index) => {
        const newLinks = [...links];
        const newDocument = [...document]
        try {
            await handleDeleteFile(document[index].fileId);
            newLinks.splice(index, 1);
            setLinks(newLinks);
            newDocument.splice(index, 1);
            setDocument(newDocument);
            setHasChanged(true);
            MessageBox({
                text: `Your has successfully been deleted`,
                type: "success"
            });

        } catch (e) {
            MessageBox({
                text: `Failed to delete the file `,
                type: "error"
            });
        }

    };

    return (
        <Form.Group className="developer_form_margin">
            <Row>
                <Col md={9}>
                    <Form.Label>{label}</Form.Label>
                </Col>
                <Col md={3}/>


            </Row>
            {links.map((link, index) => (
                <Row key={index} className="align-items-center links developer_form_margin">
                    <Col md={8}>
                        <Form.Control
                            className="ai_link"
                            type="text"
                            placeholder="Enter your link"
                            value={link}
                            onChange={e => handleChange(index, e)}
                        />
                    </Col>
                    <Col md={1}>
                        <Button variant="danger" size="sm" onClick={() => handleDeleteLink(index)} className="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                 className="bi bi-trash3-fill" viewBox="1 -1 13 20">
                                <path
                                    d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                            </svg>
                        </Button>
                    </Col>
                </Row>
            ))}
        </Form.Group>
    );
};


const Information = (
    {
        enterpriseName,
        setEnterpriseName,
        website,
        setWebsite,
        industry,
        setIndustry,
        description,
        setDescription,
        agentName,
        setAgentName,
        commonInquiries,
        setCommonInquiries,
        otherAdjustments,
        setOtherAdjustments,
        language,
        setLanguage,
        selectedFile,
        setSelectedFile,
        documentationLinks,
        setDocumentationLinks,
        referredLinks,
        setReferredLinks,
        documentationlist,
        setDocumentationlist,
        setHasChanged
    }
) => {
    const handleFileChange = async (e) => {
        const file = e.target.files[0];


        try {
            var response = await uploadFile(file);
            setSelectedFile(file);
            setDocumentationLinks([...documentationLinks, file.name]);
            setDocumentationlist([...documentationlist, response]);
            setHasChanged(true);
            MessageBox({
                text: `File successfully uploaded`,
                type: "success"
            });

        } catch (error) {
            MessageBox({
                text: `Failed to upload file please try later`,
                type: "error"
            });
            console.error(` error:`, error);
        }


    };


    return (<Container className="developer_information">
        <Form>
            <Row className="developer_form_adjustment">
                <TextareaInputField label={"Your enterprise's name:"}
                                    value={enterpriseName}
                                    setValue={setEnterpriseName}
                                    setHasChanged={setHasChanged}
                                    placeholder=""/>
                <TextareaInputField label={"Website:"}
                                    value={website}
                                    setValue={setWebsite}
                                    setHasChanged={setHasChanged}
                                    placeholder=""/>

            </Row>
            <Row className="developer_form_adjustment">
                <TextareaInputField label={"What industry does your business belong to?"}
                                    value={industry}
                                    setValue={setIndustry}
                                    setHasChanged={setHasChanged}
                                    placeholder=""/>
                <TextareaInputField label={"Please provide a brief description of your business."}
                                    value={description}
                                    setValue={setDescription}
                                    setHasChanged={setHasChanged}
                                    placeholder=""/>
            </Row>
            <Row className="developer_form_adjustment">
                <TextareaInputField label={"What would you like to name your agent?"}
                                    value={agentName}
                                    setValue={setAgentName}
                                    setHasChanged={setHasChanged}
                                    placeholder=""/>
                <TextareaInputField
                    label={"List five common inquiries that your clients might have from your AI agent:"}
                    value={commonInquiries}
                    setValue={setCommonInquiries}
                    setHasChanged={setHasChanged}
                    placeholder=""/>
            </Row>
            <Row className="developer_form_adjustment">
                <Form.Group className="developer_form_margin" as={Col} sm={5}>
                    <Form.Label>Is there any other adjustments you would like to apply to your agent?</Form.Label>
                    <Col sm={12}>
                        <Form.Control className="ai_link" as="textarea"
                                      value={otherAdjustments}
                                      onChange={e => {
                                          setOtherAdjustments(e.target.value);
                                          setHasChanged(true);
                                      }}
                                      placeholder=""/>
                    </Col>
                </Form.Group>
                <DropdownLanguage language={language}
                                  setHasChanged={setHasChanged}
                                  setLanguage={setLanguage}/>
            </Row>
            <Row className="developer_form_adjustment">

                <div className="link-columns-wrapper">
                    <Col>
                        <Row>
                            <Linkdocument links={documentationLinks}
                                          setLinks={setDocumentationLinks}
                                          document={documentationlist}
                                          setDocument={setDocumentationlist}
                                          setHasChanged = {setHasChanged}
                                          label="Your documentation files:"/>
                        </Row>
                        <Row>
                            <Col md={2}/>
                            <Col className="developer_das_information_upload_btn">
                                <UploadBtn text="Upload more" selectedFile={selectedFile}
                                           handleFileChange={handleFileChange}/>
                            </Col>
                            <Col md={2}/>
                        </Row>


                    </Col>

                    <Col>
                        <Row>
                            <LinkInputFields links={referredLinks}
                                             setLinks={setReferredLinks}
                                             setHasChanged={setHasChanged}
                                             label="Referred links on the documents:"/>
                        </Row>


                    </Col>

                </div>
            </Row>


        </Form>
    </Container>);
};

export default Information;
