import React, {useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow} from "mdb-react-ui-kit";
import {isValidEmail} from "./register";
import MessageBox from "../messageBox/MessageBox";
import "./ForgetPassword.css"
import baseurl from "../../settings/urlsettings";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const handleEmailChange = event => {
        if (!isValidEmail(event.target.value)) {
            setError('Email is invalid');
        } else {
            setError(null);
        }
        setEmail(event.target.value);
    };
    const disableButton = () => {
        console.log(email.trim());
        if (error ||
            email.trim() === ''
        )
            return true;
        else
            return false;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(baseurl+'/forgot-password', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email}),
            });
            const data = await response.json();
            MessageBox({
                type: "success",
                text: data.message
            });
        } catch (error) {
            MessageBox({
                type: "error",
                text: error.message
            });
        }
    };

    return (
        <div className='bodyForgetPassword'>
            <MDBContainer fluid>

                <MDBRow className='messs d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12' className=" py-5" id="forgetCard">

                        <MDBCard className='bg-dark text-white my-5 mx-auto mes optsmes'
                                 >
                            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                                <h2 className="messss fw-bold mb-2 text-uppercase">Forgot Password</h2>
                                <p className="text-white-50 mb-5">
                                    Lost your password?
                                    Please enter your email address.
                                    You will receive a link to  create a new password with email

                                </p>

                                <MDBInput className='change-input-sign' wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Email address'
                                          id='formControlLg' type='email'
                                          size="lg"
                                          onChange={handleEmailChange}
                                />
                                {error &&

                                    <p className="text-white-50 mb-4">{error} </p>
                                }

                                <MDBBtn outline className='dsali mx-2 px-5'
                                        color='white'
                                        size='lg'
                                        type="submit"
                                        onClick={handleSubmit}
                                        disabled={disableButton() }
                                >
                                    Reset Password
                                </MDBBtn>
                                <div>
                                <p className="jesss mb-0">Do you have an account?
                                    <a href="signin"
                                       class="text-white-50 fw-bold">
                                        &nbsp; Sign In
                                    </a>
                                </p>

                            </div>
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </div>

    );
}

export default ForgotPassword;
