import {Navigate, Route, Routes} from "react-router-dom";
import AiAgent from "./components/AIAgent/aiAgent";
import Marketplace from "./components/marketplace/marketplace";
import Community from "./components/community/community";
import Homepage from "./components/homepage/homepage";
import Roadmap from "./components/roadmap/roadmap";
import Privacypolicy from "./components/privacy-policy/privacy-policy";
import Environment_unity from "./components/enviroment/Environment_unity";
import WaitlistInfo from "./components/Waitinglist/waitlistinfo";
import Products from "./components/Waitinglist/Products";
import WaitinglistForm from "./components/Waitinglist/waitListform";
import LoginPage from "./components/loginpage/loginpage";
import Dashboard from "./components/dashboard/dashboard";
import Login from "./components/authentication/login";
import Signup from "./components/authentication/register";
import ForgotPassword from "./components/authentication/ForgetPassword";
import ResetPassword from "./components/authentication/ResetPassword";
import AccountVerification from "./components/authentication/verification";
import GeneralDashboard from "./components/dashboard/GeneralDashboard";
import * as PropTypes from "prop-types";
import Adminpage from "./components/adminpage/adminpage";
import Subscribedadmin from "./components/adminpage/subscribedadmin";
import Waitinglistadmin from "./components/adminpage/waitinglistadmin";
import Admindashboard from "./components/adminpage/admin_dashboard";
import DeveloperDashboard from "./pages/developeDashbord/developerDashbord";
import {BlogPost} from "./components/morseblog/BlogPostTemplate";
import {blogData} from "./components/morseblog/blogData";
import Bookdemo from "./components/bookDemo/bookdemo";
import React from "react";
import BetaSignUpPage from "./pages/BetaSignupPage/BetaPage";


function AppRoutes(props) {
    return <Routes>
        <Route path="/">

            <Route path="blog" element={<Community/>}/>
            <Route path="homepage"
                   element={<Homepage login={props.login}
                                      key={props.user}
                                      user={props.user}
                                      connected={props.connected}
                                      showdemo={props.showdemo}
                                      setShowdemo={props.setShowdemo}
                   />}/>
            {/*<Route path="roadmap" element={<Roadmap/>}/>*/}

            <Route path="Privacypolicy" element={<Privacypolicy/>}/>
            <Route path="BetaSignup" element={  <BetaSignUpPage />}/>
            <Route path={"developerDashboard"}
                   element={props.login ? <DeveloperDashboard key={props.user} user={props.user}/> :
                       <Homepage login={props.login}
                                 key={props.user}
                                 user={props.user}
                                 connected={props.connected}
                                 showdemo={props.showdemo}
                                 setShowdemo={props.setShowdemo}
                       />
                           }
            />


            {/*<Route path="Dashboard"*/}
            {/*       element={*/}
            {/*           <Dashboard/>*/}

            {/*       }/>*/}
            {blogData.map((blog, index) => (
                <Route
                    key={index}
                    path={blog.link}
                    element={<BlogPost blog={blog}/>}
                />
            ))}


            <Route path="loginpage"
                   element={
                       props.user ? <Navigate replace to="/"/>
                           : <LoginPage loginProps={props.loginProps}/>}/>
            <Route path="Signin"
                   element={
                       props.user ? <Navigate replace to="/"/>
                           : <Login/>}/>
            {/*<Route path="Singup"*/}
            {/*       element={*/}
            {/*           props.user ? <Navigate replace to="/"/>*/}
            {/*               : <Signup/>}/>*/}

            <Route path="forgetpassword"
                   element={
                       props.user ? <Navigate replace to="/"/>
                           : <ForgotPassword/>}/>
            <Route path="resetPassword"
                   element={
                       props.user ? <Navigate replace to="/"/>
                           : <ResetPassword/>}/>
            <Route path="verification" element={<AccountVerification/>}/>
            <Route path="profile/:id" element={<GeneralDashboard user={props.user}/>
            }/>

            {/*<Route exact path="/SpecialAi" element={<SpecialAi/>}/>*/}
            {/*<Route exact path="/ControllDashboard" element={<ControllDashboard/>}/>*/}
            <Route exact path="/Adminpage"
                   element={(props.login && props.user.email?.endsWith("morseverse.com") && props.user?.is_verified) ?
                       <Adminpage/> :
                       <LoginPage loginProps={props.loginProps}/>}/>
            <Route exact path="/Subscribedadmin"
                   element={(props.login && props.user.email?.endsWith("morseverse.com") && props.user?.is_verified) ?
                       <Subscribedadmin/> :
                       <LoginPage loginProps={props.loginProps}/>}/>

            <Route exact path="/Waitinglistadmin"
                   element={(props.login && props.user.email?.endsWith("morseverse.com") && props.user?.is_verified) ?
                       <Waitinglistadmin/> :
                       <LoginPage loginProps={props.loginProps}/>}/>
            <Route exact path="/Admindashboard"
                   element={(props.login && props.user.email?.endsWith("morseverse.com") && props.user?.is_verified) ?
                       <Admindashboard/> :
                       <LoginPage loginProps={props.loginProps}/>}/>


            <Route path="*" element={<Homepage login={props.login}
                                               key={props.user}
                                               user={props.user}
                                               connected={props.connected}
                                               showdemo={props.showdemo}
                                               setShowdemo={props.setShowdemo}
            />}/>
            <Route index
                   element={<Homepage login={props.login}
                                      key={props.user}
                                      user={props.user}
                                      connected={props.connected}
                                      showdemo={props.showdemo}
                                      setShowdemo={props.setShowdemo}
                   />}/>

        </Route>
    </Routes>;
}

AppRoutes.propTypes = {
    login: PropTypes.bool,
    connected: PropTypes.bool,
    user: PropTypes.string,
    loginProps: PropTypes.shape({
        connected: PropTypes.bool,
        disconnect: PropTypes.func,
        amount: PropTypes.string,
        wallet: PropTypes.any,
        handleConnect: PropTypes.func,
        recipient: PropTypes.string,
        handleDisconnect: PropTypes.func,
        ethersProvider: PropTypes.any,
        connecting: PropTypes.bool,
        connect: PropTypes.func
    })
};

export default AppRoutes;