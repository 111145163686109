import React, {useEffect, useState} from "react";
import "./dashboard.css"
import 'bootstrap/dist/css/bootstrap.css';
import "../carousle/card.css";
import {Card, ListGroup} from "react-bootstrap";
import ImageWithText from "../basic/ImageWithText";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import baseurl from "../../settings/urlsettings";
import {navigate} from "use-history";




function GeneralDashboard(props) {
    
    async function GetGenralUserInfo(username) {

        try {
            const response = await fetch(baseurl + "/user/"+username, {
                method: "Get",
                headers: {"Content-Type": "application/json"},
                credentials: "include"
            });
        
            return await response.json();
            if (response.ok) {
                setIsLoggedIn(true);
              }
            } 
            
            catch (error) {
                console.error('Error checking authentication:', error);
            }finally {
            setIsLoading(false);
      }
    
    }
    const params = useParams();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const Checkuser = async () => {
            try {

                console.log(location)   
                const content = await GetGenralUserInfo(params.id);
                setData(content);

            } catch (e) {
                console.log(e)
                // navigate('/')
            }
        }
        console.log(location.pathname);
        console.log(props.user.name);
        if ('/profile/'+props.user.name?.toLowerCase() === location.pathname) {
            navigate('/dashboard');
        }
        Checkuser();
    },[data])
    return (

        <div className="dushboard">
            <div className="user-profile-page">
                <section className="img-banner">
                    <img className="img-bng"
                         alt={"spaceImage"}
                         src={require('../../assets/img/IMG_5467.jpg')}/>
                </section>
                <div>
                    <div className="user-profile">
                        <div className="user-profile-container">
                            <img style={{width: "100%", height: "115%"}}
                                 src={require('../../assets/dashboard/defultImage.png')}
                                 alt="avetar Img"
                            />
                        </div>


                    </div>

                    <div className="user-info">
                        <card>
                            <Card.Body>
                                <Card.Title>contact information</Card.Title>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <ImageWithText src={require('../../assets/dashboard/username.png')}
                                                       text={data?.name}
                                                       title={"Username"}
                                                       alt={"usernameLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText src={require('../../assets/dashboard/email.png')}
                                                       text={data?.email}
                                                       title={"Email"}
                                                       alt={"emailLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText
                                            src={require('../../assets/dashboard/about.png')}
                                            text={data?.about}
                                            title={"About me"}
                                            alt={"aboutLogo"}/>

                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </card>
                        <card>
                            <Card.Body>
                                <Card.Title>social media </Card.Title>
                                <ListGroup className="user-social">
                                    <ListGroup.Item>
                                        <ImageWithText src={require('../../assets/dashboard/discord.png')}
                                                       text={data?.discord}
                                                       title={"Discord"}
                                                       alt={"discordLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText src={require('../../assets/dashboard/twitter-png-5964.png')}
                                                       text={data?.twitter}
                                                       title={"Twitter"}
                                                       alt={"TwitterLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText
                                            src={require('../../assets/dashboard/Instagram-logo-transparent-PNG.png')}
                                            text={data?.instagram}
                                            title={"Instagram"}
                                            alt={"instagrmLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText src={require('../../assets/dashboard/Logomark-White.png')}
                                                       text={data?.opensea}
                                                       title={"Opensea"}
                                                       alt={"openseaLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText
                                            src={require('../../assets/dashboard/official-linkedin-icon-png-7.jpg')}
                                            text={data?.linkedin}
                                            title={"Linkedin"}
                                            alt={"linkedinLogo"}/>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <ImageWithText
                                            src={require('../../assets/dashboard/vecteezy_tiktok-icon-png-transparent_17221712_831.png')}
                                            text={data?.tiktok}
                                            title={"Tiktok"}
                                            alt={"tiktokLogo"}/>

                                    </ListGroup.Item>

                                </ListGroup>
                            </Card.Body>
                        </card>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default GeneralDashboard;