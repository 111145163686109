import React from "react";
import {NONE} from "draft-js/lib/SampleDraftInlineStyle";
import { useEffect } from "react";


function Environment_unity(props) {
    const isEnv = window.location.pathname == "/environment";
    console.log(window.location.pathname);
    const handleStartMicrophone = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // Do something with the audio stream
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };
    useEffect(() => {
        handleStartMicrophone();
    }, []);
    // const iframeRef = useRef(null);
    // useEffect(() => {
    //   const iframe = iframeRef.current;

    //   const handleLoad = () => {
    //     handleStartCamera()
    //   };

    //   if (iframe) {
    //     iframe.addEventListener('load', handleLoad);
    //   }

    //   return () => {
    //     if (iframe) {
    //       iframe.removeEventListener('load', handleLoad);
    //     }
    //   };
    // }, [iframeRef]);
    // const [cameraStream, setCameraStream] = useState(null);

    // const handleStartCamera = async () => {
    //   try {
    //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    //     setCameraStream(stream);
    //   } catch (error) {
    //     console.error('Error starting camera:', error);
    //   }
    // }

    return (
        <div id="iframe">
            <iframe

                title="MorseVerse enviroment"
                scrolling="no"
                allowFullScreen
                frameBorder="0"
                style={
                    props?.custom ? {
                            border: "1px",
                            borderRadius: "10px",
                            borderStyle: "none",
                            borderColor: "#000000",
                            allowtransparency: "true",
                            height: "90vh",
                            width: "100%",
                        } :
                        {

                            border: "0px",
                            borderStyle: "none",
                            borderColor: "#000000",
                            allowtransparency: "true",
                            height: "100vh",
                            width: "100%"
                        }
                }
                src={(props.src && props.user.id) ? "https://www.morseverse.eu/" + props.src + "?userId=" + props.user.id :
                    (props.src) ? "https://www.morseverse.eu/" + props.src + "?userId=64ce3ffacae33c9c48417374" :
                        props.user.id ? "https://www.morseverse.eu/env?userId=" + props.user.id :
                            "https://www.morseverse.eu/env?userId=64ce3ffacae33c9c48417374"
                }


            />

        </div>
    );
}

export default Environment_unity;
