import React, {useState, useEffect} from 'react';
import {DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes} from '@mui/x-data-grid';
import './adminpage.css';
import {Sidebarsdata} from './sidebarsdata';
import baseurl from "../../settings/urlsettings.js";
import {updateUser, fetchUserlistData} from './api_for_adminpage';
import {Grid} from "@mui/material";
import MessageBox from "../messageBox/MessageBox";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Close';

export default function Adminpage() {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        try {
            handleDelete(id).then(response => {
                if (!response.ok) {
                    MessageBox({
                        type: "error",
                        text: "bad request"
                    });
                    window.location.reload();
                }
                if (response.ok) {
                    setRows(rows.filter((row) => row.id !== id));
                    MessageBox({
                        type: "success",
                        text: "Data saved successfully"
                    });
                }
            });

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = {...newRow, isNew: false};
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        try {
            handleSave(updatedRow).then(response => {
                    if (!response.ok) {
                        MessageBox({
                            type: "error",
                            text: "bad request"
                        });
                        window.location.reload();
                    }
                    if (response.ok) {
                        MessageBox({
                            type: "success",
                            text: "Data saved successfully"
                        });
                    }

                }
            );

        } catch (error) {
            MessageBox({
                type: "error",
                text: "bad request"
            });
        }

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    useEffect(() => {
        fetchUserlistData().then((data) => setRows(data));
    }, []);


    const handleSave = async (params) => {
        console.log(params)
        return await updateUser(params);
    };

    const columns = [
        {
            field: 'email',
            headerName: 'Email',
            editable: true,
            flex: 2,
        },
        {
            field: 'name',
            headerName: 'Username',
            editable: true,
            flex: 1,
        },
        {
            type: 'dateTime',
            field: 'date',
            headerName: 'JoinData',
            flex: 2,
            valueGetter: (params) => params.row.date && new Date(params.row.date),
        },
        {
            field: 'is_verified',
            headerName: 'Verified',
            flex: 1,
            type: 'boolean'
        },
        {
            field: 'about',
            headerName: 'About',
            editable: true,
            flex: 1,
        },
        {
            field: 'discord',
            headerName: 'Discord',
            valueGetter: (params) => params.row.discord || '',
            editable: true,
            // editable: editMode,
            flex: 1,
        },
        {
            field: 'linkedin',
            headerName: 'Linkedin',
            valueGetter: (params) => params.row.linkedin || '',
            editable: true,
            flex: 1,
        },
        {
            field: 'instagram',
            headerName: 'Instagram',
            valueGetter: (params) => params.row.instagram || '',
            editable: true,
            flex: 1,
        },
        {
            field: 'twitter',
            headerName: 'Twitter',
            valueGetter: (params) => params.row.twitter || '',
            editable: true,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        },


    ];

    const handleDelete = async (id) => {

        console.log('Deleting user with ID:', id);
        return await fetch(`${baseurl}/removeuser`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "userID": id,
            }),
        });
    };

    return (
        <div className='adminpage'>
            {Sidebarsdata}
            <div
                style={{
                    height: 787,
                    width: '84%',
                    marginBottom: '26px',
                    marginTop: '40px',
                    float: 'right',
                }}
            >
                <Grid>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 13},
                            },
                        }}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        pageSizeOptions={[13]}
                        checkboxSelection
                        rowKey={(row) => row.id}
                    />
                </Grid>
            </div>
        </div>
    );
}
