import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import "./uploadFile.css"

export const UploadBtn = ({text, selectedFile, handleFileChange}) => {
    return (
            <Form.Group controlId="formFile" >
                {/* Hidden file input */}
                <input
                    type="file"
                    id="fileInput"
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                />
                {/* Custom button with an image */}
                <label htmlFor="fileInput" style={{cursor: 'pointer'}}>
                    <Row>
                        <Col sm={4}>
                            <img
                                src={require("../../assets/images/cloud-computing.png")}
                                alt="Choose File"
                                style={{width: "40px", height: "40px"}}
                            />
                        </Col>
                        <Col sm={8} style={{display: 'flex', alignContent: 'center'}}>
                            {selectedFile ? (
                                <p>{text}</p>
                            ) : (
                                <p>{text}</p>
                            )}
                        </Col>
                    </Row>
                </label>
            </Form.Group>

    )
}
export const UploadFileSection = ({handleNextClick, handleBackClick}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (e) => {
        // Handle the file change event here
        const selectedFile = e.target.files[0];
        setSelectedFile(selectedFile);
        console.log('Selected file:', selectedFile);
    };

    return (
        <Container>
            <Container>
                <Row className={"upladFiletnx"}>
                    <Col>
                        <p>
                            When uploading documents to train Al agents, users are encouraged to provide a diverse range
                            of materials relevant to their specific use cases. This could include frequently asked
                            questions (FAQs), customer service interactions, instructional manuals, and any other
                            documentation reflective of the desired application. Our cutting-edge processing system
                            meticulously analyze and extract key information from these documents, discerning patterns
                            and context crucial for training Al agents effectively
                        </p>
                        <ul>
                            <li>
                                <p>
                                    you will be able to upload additional documents at a later time or modify your
                                    existing files as needed.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You have the flexibility to upload files in various formats, including .txt, .pdf,
                                    and .docx.
                                </p>
                            </li>
                        </ul>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <button className="check-button-ai" onClick={handleBackClick}>
                            <p className="check-link-ai">Back</p>
                        </button>

                    </Col>
                     <Col className="uploadFile">
                    <UploadBtn selectedFile={selectedFile} text={"Upload your file"}
                               handleFileChange={handleFileChange}/>
                     </Col>
                    <Col className="specialai-button-me1">
                        <div className="specialai-button-me">
                            <button className="check-button-ai" onClick={handleNextClick}>
                                <p className="check-link-ai">Next</p>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}