export const Sidebarsdata = (
    <div key="sidebar" className='sidebarsdata'> {/* Adding a unique key to the div */}
        <li className="topic-main topics-mains">Controll Panel</li>
        <ul className="topics">
            <li><a href='/admindashboard' className="a-topics a-topicss">Dashboard </a></li>

            <li><a href='/adminpage' className="activate active a-topics a-topicss">User Edit</a></li>

            <li><a href='/subscribedadmin' className="a-topics a-topicss">Subscription</a></li>

            <li><a href='/waitinglistadmin' className="a-topics a-topicss">Waitinglist </a></li>
        </ul>
    </div>
);
  