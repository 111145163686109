import {useState} from "react";

import StandaredLoginPopUp from "./standaredLoginPopUp";
import StandaredSignUpPopUp from "./registerPop";


const PopUpAuth = () => {
    const [show, setShow] = useState(false);
    const [showlogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const hidelogin = () => {
        setShowLogin(false);
    }
    const showloginfn = () => {
        setShowLogin(true);
        setShowSignUp(false);
    }
    const hideSignup = () => {
        setShowSignUp(false);
    }
    const showsignupfn = () => {
        setShowSignUp(true);
        setShowLogin(false);
    }

    return (<>
            <button className="nav-login nav-stt"
                    onClick={e => {
                        setShowLogin(true);
                        setShow(true);
                    }}>Admin Login
            </button>
            <StandaredLoginPopUp show={showlogin}
                                 hide={hidelogin}
                                 signup={showsignupfn}
                                 key={showlogin}/>
            {/*<StandaredSignUpPopUp show={showSignUp}*/}
            {/*                      hide={hideSignup}*/}
            {/*                      login={showloginfn}*/}
            {/*                      key={showSignUp}/>*/}
        </>

    );
}
export default PopUpAuth;