import React, {useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination, Virtual} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './slider.css';
import {slideData} from "./data/slideData";


const BackgroundSlider = ({title}) => {
    const [swiperRef, setSwiperRef] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const goToSlide = (index) => {
        if (swiperRef.loop) {
            console.log("hi");
            const realIndex = swiperRef.loopedSlides + index; // Adjust index for loop
            console.log(realIndex)
            setActiveIndex(index)
            swiperRef.slideTo(realIndex, 800);
        } else {
            setActiveIndex(index)
            swiperRef.slideTo(index, 800);

        }
    };

    return (<Container className={"swiperContainer"}>
        <Row>
            <Col lg={1} sm={1}/>
            <Col>
                <div className="main-swiper">
                    <Swiper
                        onSwiper={setSwiperRef}
                        modules={[Navigation, Pagination, A11y, Virtual]}
                        spaceBetween={-400}
                        slidesPerView={1.8}
                        centeredSlides={true}
                        // loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1, spaceBetween: -100,
                            }, 768: {
                                slidesPerView: 1.1, spaceBetween: -200,
                            }, 1024: {
                                slidesPerView: 1.8, spaceBetween: -400,
                            },
                        }}

                        autoplay={{
                            delay: 54000,  // Milliseconds between the slides transition
                            disableOnInteraction: false  // Continue autoplay after interaction
                        }}
                        coverflowEffect={{
                            rotate: 0, stretch: 0, depth: 300, modifier: 3.7, slideShadows: false// Increases the effect on the active slide
                        }}

                        effect="coverflow"
                        onSlideChange={(swiper) => {
                            console.log('active index:', swiper.activeIndex);
                            setActiveIndex(swiper.activeIndex);
                        }}

                    >
                        {slideData.map((data, index) => (<SwiperSlide key={index} virtualIndex={data.index}>
                                <div className={"video_contaier"}>
                                    <div className={"video_contaier_1"}>

                                        <video className={"vedio_banner"}
                                               playsInline
                                               autoPlay
                                               muted
                                               loop
                                        >
                                            <source
                                                src={data.video}
                                                type="video/mp4"
                                            />

                                        </video>
                                    </div>

                                </div>

                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div>

            </Col>
            <Col lg={1} sm={1}/>
        </Row>
        <Row>
            <Col md={0} lg={3}/>
            <Col md={12}>
                <Row className={"container-home_page_slider"}>
                    {slideData.map((data, index) => (
                        <Col lg={4}>
                            <Button className={"home_page_slider"} key={data.label}
                                    style={{
                                        backgroundColor: activeIndex === index ? '#0c5460' : '#78c7d2',
                                        color: activeIndex === index ? '#fff' : '#000'
                                    }}
                                    onClick={() => goToSlide(data.index)}>
                                {data.label}
                            </Button>
                        </Col>


                    ))}
                </Row>

            </Col>
            <Col md={0} lg={3}/>

        </Row>
    </Container>)
};
export default BackgroundSlider;