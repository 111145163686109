import {Appearance_} from "../../components/special-ai/special_ai_customize_appearance";
import {Container} from "react-bootstrap";

const Appearance = ({
                        selectedOffice,
                        setSelectedOffice,
                        selectedCharacter,
                        setSelectedCharacter,
                        setHasChanged
                    }) => {
    return (
        <>
            <Appearance_
                selectedOffice={selectedOffice}
                setSelectedOffice={setSelectedOffice}
                selectedCharacter ={selectedCharacter}
                setSelectedCharacter={setSelectedCharacter}
                setHasChanged={setHasChanged}
            />
        </>

    )
};
export default Appearance;