import React from "react";
import Container from "react-bootstrap/Container";
import {Row, Col} from "react-bootstrap";
import "./customize_appearance.css";

export const CustomizeAppearanceSection = ({handleNextClick, handleBackClick}) => {


    return (
        <Container>
            <Container>
                <Row>
                    <ul className={"custom-list"}>
                        <li>Here you can customize the appearance of your AI Agent and its office.</li>
                    </ul>
                </Row>
                <Appearance_/>

                <Row>
                    <Col>
                        <div className="">
                            <button className="check-button-ai" onClick={handleBackClick}>
                                <p className="check-link-ai">Back</p>
                            </button>
                        </div>
                    </Col>
                    <Col className="specialai-button-me1">
                        <div className="specialai-button-me">
                            <button className="check-button-ai" onClick={handleNextClick}>
                                <p className="check-link-ai">Next</p>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export const Appearance_ = ( {selectedOffice,
                             setSelectedOffice,
                             selectedCharacter,
                             setSelectedCharacter,
                             setHasChanged }) => {

    const handleRadioChangeOffice = (event) => {
        setSelectedOffice(event.target.value);
        setHasChanged(true);
    };

    const handleRadioChangeCharacter = (event) => {
        setSelectedCharacter(event.target.value);
        setHasChanged(true);
    };

    const isOfficeSelected = (value) => selectedOffice === value;
    const isCharacterSelected = (value) => selectedCharacter === value;
    return (
        <>
            <Row className={"seclectionText"}>
                <ul className={"custom-list"}>
                    <li>Choose office</li>
                </ul>
            </Row>
            <Row className={"seclectionText"}>
                <Col>
                    <label className={`radio-image ${isOfficeSelected("option1") && "selected"}`}>
                        <input
                            type="radio"
                            value="option1"
                            checked={isOfficeSelected("option1")}
                            onChange={handleRadioChangeOffice}
                        />
                        <img
                            src={require("../../assets/specialAI/option1.png")}
                            alt="Option 1"
                        />
                    </label>
                </Col>
                <Col>
                    <label className={`radio-image ${isOfficeSelected("option2") && "selected"}`}>
                        <input
                            type="radio"
                            value="option2"
                            checked={isOfficeSelected("option2")}
                            onChange={handleRadioChangeOffice}
                        />
                        <img
                            src={require("../../assets/specialAI/option2.png")}
                            alt="Option 2"
                        />
                    </label>
                </Col>
                <Col>
                    <label className={`radio-image ${isOfficeSelected("option3") && "selected"}`}>
                        <input
                            type="radio"
                            value="option3"
                            checked={isOfficeSelected("option3")}
                            onChange={handleRadioChangeOffice}
                        />
                        <img
                            src={require("../../assets/specialAI/option3.png")}
                            alt="Option 3"
                        />
                    </label>
                </Col>
            </Row>

            <Row className={"seclectionText"}>
                <ul className={"custom-list"}>
                    <li>Choose character</li>
                </ul>
            </Row>
            <Row className={"seclectionText"}>
                <Col>
                    <label className={`radio-image ${isCharacterSelected("option1") && "selected"}`}>
                        <input
                            type="radio"
                            value="option1"
                            checked={isCharacterSelected("option1")}
                            onChange={handleRadioChangeCharacter}
                        />
                        <img
                            src={require("../../assets/specialAI/ReadyPlayerMe-Avatar-4.png")}
                            alt="Option 1"
                        />
                    </label>
                </Col>
                <Col>
                    <label className={`radio-image ${isCharacterSelected("option2") && "selected"}`}>
                        <input
                            type="radio"
                            value="option2"
                            checked={isCharacterSelected("option2")}
                            onChange={handleRadioChangeCharacter}
                        />
                        <img
                            src={require("../../assets/specialAI/ReadyPlayerMe-Avatar-5.png")}
                            alt="Option 2"
                        />
                    </label>
                </Col>
                <Col>
                    <label className={`radio-image ${isCharacterSelected("option3") && "selected"}`}>
                        <input
                            type="radio"
                            value="option3"
                            checked={isCharacterSelected("option3")}
                            onChange={handleRadioChangeCharacter}
                        />
                        <img
                            src={require("../../assets/specialAI/ReadyPlayerMe-Avatar-6.png")}
                            alt="Option 3"
                        />
                    </label>
                </Col>
            </Row>
        </>
    )
};