export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    dots: false,
    items: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const productData = [
  {
    id: 10,
    display: "none",
    imageurl: require("../../../assets/images/Screenshot (1074).png"),
    name: "Explore Search Room",
     url:"https://www.morseverse.com/environment",
    price: "$99.99",
    description:  "You can surf on the web while enjoying the metaverse tools in our seach room. Try it now by clicking",
    link: 'https://www.morseverse.com/environment"',
    linktext: 'here'
  },
  {
    id: 9,
    display: "none",
    imageurl: require("../../../assets/images/photo_2023-07-30_18-07-38.jpg"),
    name: "Check out Morse Agent",
     url:"https://www.morseverse.com/environment",
    price: "$99.99",
    description:  "Our search agent, Morse, let you have access to the world wide Web and explore it in a unique 3D user interface.",
    link: null,
    linktext: null
  },
  {
    id: 8,
    display: "none",
    imageurl: require("../../../assets/images/Screenshot (954).jpg"),
    name: "Talk to AIMorse",
     url: "/aimorse",
    price: "$99.99",
    description:  "AIMorse our primary AI agent is now ready to use, giving you answers for your general questions. Check out our AI agent now by clicking ",
    link: '/aimorse',
    linktext: 'here'
  },
  {
  id: 7,
    display: "none",
    imageurl: require("../../../assets/images/01.jpg"),
    name: "Explore NewYork",
     url:"https://www.morseverse.com/environment",
    price: "$99.99",
    description:  "First part of our real-world 3D map has been published. you can explore it now.",
    link: null,
    linktext: null
  },
  {
    id: 6,
    display: "none",
    imageurl: require("../../../assets/images/enviorment.jpg"),
    name: "Gate of Heaven",
     url:"https://www.morseverse.com/environment",
    price: "$99.99",
    description: "Initial environment of the MorseVerse, Gate of Heaven, just launched. create your avatar and jump into it.",
    link: null,
    linktext: null
  },
  {
    id: 5,
    display: "none",
    imageurl: require("../../../assets/images/readyplay.jpg"),
    name: "Partnership announcement",
     url:"https://readyplayer.me/",
    price: "$99.99",
    description: "Thrilled to announce our exciting partnership with ready player me \n" +
      "you can now create cross-application avatars via MorseVerse and use it in our metaverse and hundreds of other games and apps.",
    link: "https://readyplayer.me/",
    linktext: "ready player website"
  },
  {
    id: 4,
    display: "none",
    imageurl: require("../../../assets/images/photo_2022-10-22_12-36-04.jpg"),
    name: "Gate of Heaven",
     url:"https://www.morseverse.com/environment",
    price: "$99.99",
    description:
      "First environment of our metaverse will be available to explore soon. stay tuned for the later updates on our social media and website.",
      link: null,
    linktext: null
  },
  {
    id: 3,
    display: "none",
    imageurl: require("../4758651_1472.jpg"),
    name: "Roadmap",
    url: "/roadmap",
    description:
      "Roadmap is finally up you can now see what we are doing and what we are going to do in near future.",
    link: null,
    linktext: null
  },
  {
    id: 2,
    display: "none",
    imageurl: require("../11235938_11121.jpg"),
    name: "Privacy Policy",
    url: "/PrivacyPolicy",
    description:
      "Our Privacy Policy published you can now check it to see our terms and find out how we handle our customer's or client's information gathered in our operations.",
    link: null,
    linktext: null
  },
  {
    id: 1,
    display: "none",
    imageurl: require("../../../assets/images/homepage_comminity.jpg"),
    name: "Website",
    url: "https://www.morseverse.com/",
    description:
      "We did our best to represent “Morseverse” with different scene prototypes of our metaverse and tried to explain our goals, roadmap and future of Morseverse in it.",
    link: null,
    linktext: null
  }


];
